<template>
<div>
  <v-app-bar class="center-bar" elevation="0" color="primary" dark app>
    <v-app-bar-title class="text-center" color="white"> Услуги
    </v-app-bar-title>
  </v-app-bar>
  <v-container>
    <p class="font-weight-medium ma-0">При выборе категории услуг на данной странице Вы будете перенаправлены в сервис inDrive</p>
  </v-container>
  <v-divider style="border-width: initial;"></v-divider>
  <v-container>
    <v-row class="mt-2">
      <template v-for="(item, i) in services">
        <v-col cols="6" md="4" lg="3" :key="i">
          <v-card 
            class="service-card text-center pa-2"
            hover
            flat
            :href="item.src"
            :disabled="isDisabled"
            @click="disableButton"
          >
            <div>
              <div>
                <component :is="item.logo"></component>
              </div>
              <span >{{ item.title }}</span>
            </div>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</div>
</template>

<script>
import repairLogo from "@/assets/services/Group.svg"
import CleaningLogo from "@/assets/services/Group1.svg"
import TechLogo from "@/assets/services/Group2.svg"
import RoadLogo from "@/assets/services/Group3.svg"
import KidsLogo from "@/assets/services/Group4.svg"
import BeautysLogo from "@/assets/services/Group5.svg"
import SpecialsLogo from "@/assets/services/Group6.svg"
import AnothersLogo from "@/assets/services/Group7.svg"
export default {
  data() {
    return {
      isDisabled: false,
      services: [
        {
          logo: repairLogo,
          title: 'Ремонт и строительство',
          src: 'https://services.indrive.com/catalog/1?cityId=150?utm_source=qr_code&utm_medium=kazakhstan_almaty&utm_campaign=gtm.client.masters-aula-web-repair.021222'
        },
        {
          logo: CleaningLogo,
          title: 'Уборка, помощь по дому',
          src: 'https://services.indrive.com/catalog/6?cityId=150?utm_source=qr_code&utm_medium=kazakhstan_almaty&utm_campaign=gtm.client.masters-aula-web-cleaning.021222'
        },
        {
          logo: TechLogo,
          title: 'Ремонт и установка техники',
          src: 'https://services.indrive.com/catalog/19?cityId=150?utm_source=qr_code&utm_medium=kazakhstan_almaty&utm_campaign=gtm.client.masters-aula-web-installation.021222'
        },
        {
          logo: RoadLogo,
          title: 'Грузоперевозки',
          src: 'https://services.indrive.com/catalog/19?cityId=150?utm_source=qr_code&utm_medium=gtm.client.masters-aula-web-loaders.021222&utm_campaign=gtm.client.masters-aula-web-loaders.021222'
        },
        {
          logo: KidsLogo,
          title: 'Все для детей',
          src: 'https://services.indrive.com/catalog/29?cityId=150?utm_source=qr_code&utm_medium=kazakhstan_almaty&utm_campaign=gtm.client.masters-aula-web-kids.021222'
        },
        {
          logo: BeautysLogo,
          title: 'Красота и здоровье',
          src: 'https://services.indrive.com/catalog/5?cityId=150?utm_source=qr_code&utm_medium=kazakhstan_almaty&utm_campaign=gtm.client.masters-aula-web-beauty.021222'
        },
        {
          logo: SpecialsLogo,
          title: 'Услуги спецтехники',
          src: 'https://services.indrive.com/catalog/20?cityId=150?utm_source=qr_code&utm_medium=kazakhstan_almaty&utm_campaign=gtm.client.masters-aula-web-machinery.021222'
        },
        {
          logo: AnothersLogo,
          title: 'Разное',
          src: 'https://services.indrive.com/catalog/17?cityId=150?utm_source=qr_code&utm_medium=kazakhstan_almaty&utm_campaign=gtm.client.masters-aula-web-misc.021222'
        },
      ]
    }
  },
  methods: {
    disableButton() {
      this.isDisabled = true
    setTimeout(() => { this.isDisabled = false }, 3000);
    },
  },
}
</script>

<style scoped>
.service-card {
  background: #EBFAF0;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
</style>