import $api from "@/api/index";
export default {
  state: {
    item: null,
    data: null,
  },
  mutations: {
    setReq(state, newReq) {
      return state.data = newReq
    },
    setItem(state, newReq) {
      return state.item = newReq
    }
  },
  actions: {
    async getReqs(context, data) {
      const res = await $api.requisitions.load_requisitions(data)
      context.commit("setReq", res.data);
    },
    async getReqItem(context, {id}) {
      try {
        const res = await $api.requisitions.load_requisition(id)
        context.commit("setItem", res);
      } catch(error) {
        console.log('err', error)
      }
    }
  },
  getters: {
    Requisitions(state) {
      return state.data;
    },
    Requisition(state) {
      return state.item;
    },
  }
}