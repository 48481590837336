<template lang="pug">
div 
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon to="/menu")
      BackLogo
    v-app-bar-title.text-center Профиль
    v-spacer
    v-btn.pa-0(text @click="logout") Выйти
  //- v-divider
  v-card.profile-card.pt-3(elevation="0" color="primary")
    div.d-flex.justify-center
      v-avatar(width="100" height="100" style="border: 2px solid white;")
        img(v-if="profile" :src="avatar")
        AvatarLogo(v-else)
      div(style="position: absolute; margin-left: 115px; margin-top: 75px;")
        v-btn(icon color="white")
          cameraPlusLogo
    div.d-flex.justify-center.mt-2
      p.ma-0.ft-20.white--text  {{ getFullname(profile) }}
    div.d-flex.justify-center
      p.white--text.text-light(v-if="profile") {{ profile.phone }}
  Spinner(v-if="!profile")
  v-container(v-else)
    v-list
      h5.ft-14 Ваш статус
      v-list-item.pa-0
        v-list-item-content.pa-0
          v-text-field(v-model="profile.social_status" placeholder="Что у вас нового?" counter="15" prepend-icon="mdi-at")
        v-list-item-icon
          MessageLogo
      h5.ft-14 Телефон
      v-list-item.pa-0
        v-list-item-content.pa-0
          v-text-field(v-model="profile.phone" placeholder="Введите номер телефона" prepend-icon="mdi-cellphone")
        v-list-item-icon
          v-btn(icon @click="profile.phone_hidden = !profile.phone_hidden")
            v-icon(v-if="!profile.phone_hidden") mdi-eye-outline
            v-icon(v-else) mdi-eye-off
      h5.ft-14 Имя
      v-list-item.pa-0
        v-list-item-content.pa-0
          v-text-field(v-model="fullname" placeholder="Введите имя и фамилию" prepend-icon="mdi-cellphone" hide-details)
          p.text-caption Соседи будут видеть вас по этому имени
        v-list-item-icon
          v-btn(icon @click="profile.fio_hidden = !profile.fio_hidden")
            v-icon(v-if="!profile.fio_hidden") mdi-eye-outline
            v-icon(v-else) mdi-eye-off
    v-btn(block color="primary" @click="saveProfile" :loading="loading") Сохранить
</template>

<script>
import { mapGetters } from "vuex";
import { getFullname } from "@/helpers/helpers"
import BackLogo from "@/assets/img/back-button.svg"
import AvatarLogo from "@/assets/img/avatar-profile.svg"
import MessageLogo from "@/assets/img/message.svg"
import cameraPlusLogo from "@/assets/img/camera-plus.svg"
export default {
  data() {
    return {
      profile: null,
      loading: false,
    }
  },
  watch: {
    'profileStore' () {
      this.profile = this.profileStore
    }
  },
  computed: {
    ...mapGetters({
      profileStore: "getProfile",
      Token: "Token"
    }),
    avatar() {
      return (`${window.localStorage.env_api}/${this.profile.avatar}`)
    },
    fullname: {
      get: function() {
        if(getFullname(this.profile) == 'ФИО не указано') {
          return ''
        } else {
          return getFullname(this.profile)
        }
      },
      set: function(newValue) {
        console.log('newValue', newValue.indexOf(' ') >= 0)
        if(newValue.indexOf(' ') > 0) {
          var parts = newValue.split(' ');
          this.profile.name = parts[0];
          this.profile.surname = parts[parts.length - 1];
        }
        
      }
    }
  },
  methods: {
    getFullname,
    async saveProfile() {
      this.loading = true
      let params = {
        name: this.fullname.split(" ")[0],
        surname: this.fullname.split(" ")[1],
        patronymic: this.fullname.split(" ")[2],
        iin: this.profile.iin,
        iin_hidden: this.profile.iin_hidden,
        social_status: this.profile.social_status,
        email: this.profile.email,
        email_hidden: this.profile.email_hidden,
        fio_hidden: this.profile.fio_hidden,
        phone_hidden: this.profile.phone_hidden,
      }
      try {
        await this.$api.user.edit_data(params);
        this.$store.dispatch('fetchProfile')
        this.loading = false
      } catch (error) {
        console.log('error', error)
      }
    },
    logout() {
      this.$store.dispatch('logout')
      window.location.assign(`/#/login`);
    }
  },
  created() {
    if(!this.Token && this.$route.name !== 'login') {
      this.$router.push('/login')
    }
    this.$store.dispatch('fetchProfile')
  },
  components: {
    BackLogo,
    AvatarLogo,
    MessageLogo,
    cameraPlusLogo
  }
}
</script>

<style scoped>
.ft-12 {
  font-size: 12px;
}
.ft-14 {
  font-size: 14px;
}
</style>

<style>
.profile-card.v-sheet.v-card {
  border-radius: 0px 0px 16px 16px;
}
</style>