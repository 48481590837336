<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon @click="$router.go(-1)")
      BackLogo
    v-app-bar-title.text-center(color="white") Пользовательское соглашение
  div.mx-5.mt-2
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:center;background:white;'><strong>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</strong></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:center;background:white;'><strong>об использовании мобильного приложения и ресурсов &laquo;AULA&raquo; (aula.kz, aula.pro)</strong></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;background:white;'><strong>&nbsp;</strong></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;background:white;'><strong>г. Нур-Султан &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&laquo;27&raquo; апреля 2022 г.</strong></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>&nbsp;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>Настоящим, Товарищество с ограниченной ответственностью &laquo;Digital Business Services&raquo;, именуемое в дальнейшем &laquo;Правообладатель&raquo;, предлагает физическим и юридическим лицам, в дальнейшем именуемым &laquo;Пользователь&raquo;, предоставление доступа к функционалу мобильного приложения &laquo;AULA&raquo;. Настоящее предложение в соответствии со статьей 395 Гражданского кодекса Республики Казахстан является публичной̆ офертой̆ (далее &mdash; Соглашение), полным и безоговорочным принятием (акцептом) условий которой̆ считается установка Приложения в мобильное и/или иное устройство Пользователя и Регистрация в нем.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>&nbsp;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:center;background:white;'><strong>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</strong></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>1.1. В Соглашении и вытекающих или связанных с ним отношениях Сторон применяются следующие термины:</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong><span style="background:white;">Голосование</span></strong><span style="background:white;">&nbsp;- процесс принятия решений собственниками квартир, нежилых помещений, связанных с управлением объектом кондоминиума и содержанием общего имущества объекта кондоминиума, осуществляемый путем открытого волеизъявления на собрании, проводимом посредством объектов информатизации в сфере жилищных отношений и жилищно-коммунального хозяйства;</span></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>ЖКУ &ndash;&nbsp;</strong>предоставляемый̆ Услугодателем Пользователю комплекс услуг, предусмотренный̆ Законом Республики Казахстан &laquo;О жилищных отношениях&raquo; (Далее по тексту - Закон).</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Услугодатель -&nbsp;</strong>юридическое лицо избранное общим собранием собственников квартир, нежилых помещений и зарегистрированное в уполномоченном государственном органе, а также организации заключившие индивидуальные договоры с Пользователями на оказание услуг, получившее неисключительное право использования мобильного приложения и ресурсов &laquo;AULA&raquo; на основании договора, заключенного с Правообладателем, и оказывающее Пользователю ЖКУ и Прочие услуги (организации, осуществляющие функции управления многоквартирными жилыми домами и/или иными объектами эксплуатации, ресурсоснабжающие организации, иные организации).</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Личный кабинет&nbsp;</strong>&ndash; персональный раздел Приложения, к которому Пользователь получает доступ после прохождения регистрации и/или авторизации в Приложении. Личный кабинет предназначен для хранения персональных данных Пользователя, просмотра и управления доступными функциональными возможностями Приложения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Платеж -&nbsp;</strong>действия Пользователя, направленные на перевод денежных средств в счет исполнения денежных обязательств Пользователя перед Поставщиком по оплате за ЖКУ и Прочие услуги.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Платежный документ&nbsp;</strong>&ndash; документ, формируемый в Приложении, содержащий информацию о начислениях за ЖКУ и Прочие услуги за расчетный период и итоговой задолженности перед Поставщиком.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Плательщик&nbsp;</strong>- Пользователь, осуществляющий Платеж в целях исполнения денежных обязательств перед Поставщиками за ЖКУ и иные услуги.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong>Политика конфиденциальности&nbsp;</strong>- документ, определяющий политику Правообладателя в отношении обработки персональных данных и защиты конфиденциальной информации и размещенный в свободном доступе в Приложении. Заключая настоящее Соглашение, Пользователь подтверждает, что ознакомлен с Согласием на обработку персональных данных и условия оказания услуг третьими лицами и в полном объеме принимает ее условия.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Пользователь&nbsp;</strong>&ndash; любое дееспособное физическое лицо, заключившее Соглашение с Правообладателем в личных целях или выступающее от имени и в интересах представляемого им юридического лица в соответствии с требованиями законодательства и Соглашения, которому Правообладателем предоставляется право на использование Приложения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Поставщик&nbsp;</strong>- Услугодатель, в отношении которого Правообладателем оказываются услуги по средством Приложения, а также включая, но не ограничиваясь сбором платежей за ЖКУ, целевые сборы, работы по взысканию дебиторской задолженности, проведения письменного опроса, а также иное организационно-технологическое взаимодействие Правообладателя и третьих лиц.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'><strong>Правообладатель&nbsp;</strong>&ndash; Товарищество с ограниченной ответственностью &laquo;Digital Business Services&raquo;, БИН 190140015028, г.Нур-Султан, ул. Е 10, д. 17М, БИК CASPKZKA, ИИК KZ63722S000002014950 KZT, АО &laquo;Kaspi Bank&raquo;<strong>.</strong> Правообладателю принадлежит исключительное право на Приложение.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;'><strong>Приложение&nbsp;</strong>&ndash; мобильное приложение &laquo;AULA&raquo; - принадлежащая Правообладателю программа для ЭВМ, разработанная для мобильных устройств, работающих под управлением операционных систем Android и Apple iOS, являющаяся компонентом &laquo;AULA&raquo;<span style="background:white;">&nbsp;Property Management System</span>.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>&laquo;AULA&raquo;<span style="background:white;">&nbsp;Property Management System</span> - принадлежащая Правообладателю программа для ЭВМ, результат исследовательской деятельности Правообладателя, направленный на автоматизацию процессов в сфере управления многоквартирными &nbsp;жилыми домами и иными объектами эксплуатации, компонентом которого является Приложение.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Прочие услуги&nbsp;</strong>- предоставляемые Правообладателем, Услугодателем и третьими лицами Пользователю услуги, не относящиеся к ЖКУ, которые оплачиваются последними с использованием &laquo;AULA&raquo;<span style="background:white;">&nbsp;Property Management System</span> и/или иными способами.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Регистрация&nbsp;</strong>&ndash; добровольное действие Пользователя, направленное на создание Учетной записи и Личного кабинета. Пользователь несет ответственность за достоверность, актуальность, полноту и соответствие законодательству Республики Казахстан предоставленной при регистрации информации, ее чистоту от претензий третьих лиц, а также выражает свое согласие на обработку предоставляемых персональных данных на условиях и в порядке, предусмотренных Согласием на обработку персональных данных и условия оказания услуг третьими лицами.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Сайт&nbsp;</strong>&ndash; сайт в информационно-телекоммуникационной сети &laquo;Интернет&raquo; по адресу: AULA.KZ, AULA.CITY включая все под-доменные имена указанного домена.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Комиссия&nbsp;</strong>- плата за использование функционала Приложения, реализующего возможность оплаты Платежных документов, взимаемая Правообладателем с Пользователя в момент осуществления Платежа в целях обеспечения возможности осуществления Платежей за ЖКУ и Прочие услуги посредством Приложения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Техническая поддержка -&nbsp;</strong>деятельность Правообладателя по обеспечению нормального функционирования Сервиса, устранению ошибок и сбоев по заявкам Услугодателя и Пользователей, поступающим на адрес: <a href="about%3Ablank"><span style="color:windowtext;">support@aula.</span></a><u>city</u> и/или по телефонам в рабочие дни (с понедельника по пятницу) и время с 10.00 часов до 18.00 часов по контактным тел.: 8 778 407 12 19; 8 (7172) 78-04-88.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'><strong>Учетная запись&nbsp;</strong>&ndash; запись в системе, хранящая данные, позволяющие идентифицировать и авторизовать Пользователя.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>1.2. В Соглашении могут быть использованы иные термины. В этом случае толкование такого термина производится в соответствии с текстом Соглашения, а в случае отсутствия однозначного толкования термина в тексте Соглашения - в соответствии с его толкованием или способами толкования, определенными в соответствии с действующим Законодательством Республики Казахстан.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:center;background:white;'><strong>2. ПРЕДМЕТ СОГЛАШЕНИЯ</strong></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'>2.1. Правообладатель предоставляет Пользователю возможность использования Приложения с целью взаимодействия с Услугодателем и с другими Пользователями по вопросам содержания общего имущества, собственности, находящейся в индивидуальной частной собственности, а также получения уведомлений.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'>2.2. Правообладатель безвозмездно предоставляет Пользователю доступ к базовому функционалу Приложения в порядке и на условиях, определенных Соглашением (простую (неисключительную) лицензию), за исключением случаев наличия иных договорных отношений, а Пользователь обязуется использовать Приложение в соответствии с условиями Соглашения и действующего законодательства.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>2.3. Некоторые функциональные возможности Приложения могут быть предоставлены Пользователю на отдельных условиях, регулируемых, в частности, разделом 4 настоящего Соглашения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>2.4. Право использования Приложения в соответствии с Соглашением включает в себя воспроизведение ПО, которое предусматривает возможность осуществлять любые действия, связанные с функционированием Приложения в соответствии с его назначением, а именно:</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- право установки (записи в память Мобильного устройства) одного экземпляра Приложения;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- право запуска Приложения исключительно для его использования с целью получения Услуг лично Пользователем.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>2.5. Приложение может быть использовано Пользователем только лично.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>2.6. Приложение предоставляет Пользователю удобный способ взаимодействия с Услугодателями посредством следующего функционала:</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- оформление заявок Услугодателю;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- отслеживание статуса заявок;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- уведомления от Правообладателя, Услугодателя и третьих лиц в том числе и рекламного характера;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- возможность добавления нескольких адресов;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- возможность выбора типа помещения;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- передача показаний индивидуальных приборов учета;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- просмотр истории показаний индивидуальных приборов учета;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- выгрузка начислений за ЖКУ и Прочие услуги;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- возможность формирования единого Платежного документа, содержащего</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>комбинацию ЖКУ и прочих услуг, предоставляемых различными Поставщиками, по выбору Пользователя, просмотр и сохранение Платежных документов; возможность оплаты Платежных документов; архив Платежных документов, история Платежей;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- участия в принятии решений по вопросам управления и содержания составом общего имущества путем голосования с использованием Сайта.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>2.7. Функциональные возможности, доступные для Пользователя, могут быть уникальными и зависят от местонахождения Пользователя, условий договоров Пользователя с Услугодателем, условий договоров между Правообладателем, Услугодателем и третьими лицами.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>&nbsp;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:center;background:white;'><strong>3. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ПРИЛОЖЕНИЯ</strong></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>3.1. Для получения доступа к Приложению необходимо скачать Приложение в мобильное устройство и разрешить доступ к данным мобильного устройства для обеспечения работы ряда функций Приложения, а именно:</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- доступ к камере устройства. Данный доступ необходим для прикрепления фотографий при создании заявок/уведомлений/публикаций, а также при сканировании QR;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- доступ к геолокации устройства. Данный доступ необходим для работы карты через которую Пользователь добавляет свой дом;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- управление Bluetooth соединением. Данный доступ необходим для соединения устройства с модулем Bluetooth при сканировании QR. Связь с модулем Bluetooth требуется при открытии домофона/щлагбаума и т.п.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- доступ к списку контактов для оперативной и удобной подгрузке номеров телефонов исполнителей прикрепляемых к конкретной заявке;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- доступ к файловой системе устройства. Данный доступ необходим для прикрепления файлов при создании заявок/уведомлений/публикаций;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- информация о версии операционной системы и модели устройства. Данная информация проверяется для определения совместимости устройства с версией Приложения;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- информация об IP-адресе и адресе точки подключения пользователя. Данная информация необходима в целях безопасности при расследовании возможных инцидентов.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>3.2. Для получения доступа к функциональным возможностям Приложения и приобретения статуса Пользователя необходимо осуществить Регистрацию в Приложении. Для Регистрации Пользователю необходимо ввести номер мобильного телефона (зарегистрированный либо используемый Пользователем номер мобильных операторов связи) и подтвердить действие Соглашения. Завершить регистрацию необходимо посредством ввода кода из поступившего на указанный номер SMS сообщения и внести необходимую информацию (ФИО, адрес помещения, а также иную информацию запрашиваемую мобильным приложением).</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>3.3. Для получения доступа к отдельным функциональным возможностям Приложения может потребоваться внесение пользователем дополнительной информации (выбор Поставщиков ЖКУ и Прочих услуг, номер лицевого счета, иное).</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>3.4. Для использования Приложения необходимо подключение к Интернету. Правообладатель не несет ответственность за возможные неблагоприятные последствия для Пользователя, вызванные невозможностью подключения к Интернету и его бесперебойного функционирования.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:center;background:white;'><strong>4. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ФУНКЦИОНАЛА ПРИЛОЖЕНИЯ, РЕАЛИЗУЮЩЕГО ВОЗМОЖНОСТЬ ОПЛАТЫ ПЛАТЕЖНЫХ ДОКУМЕНТОВ</strong></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>4.1. Приложение реализует возможность безналичной оплаты Платежных документов с использованием банковских карт.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>4.2. Безналичная оплата осуществляется Плательщиком с участием уполномоченного оператора по приему платежей/оператора электронных денежных средств и регулируется правилами международных платежных систем, законодательством Республики Казахстан, а также иными возможностями регламентированными национальным Банком Республики Казахстан (в том числе банка-эмитента карты Плательщика), иных участников расчетов.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>4.3. Финансовые услуги по переводу денежных средств оказывают банки второго уровня по средствам собственных продуктов.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>4.4. Правообладатель не оказывает какие-либо финансовые услуги, не осуществляет действий по сбору и распределению в адрес получателей Платежей денежных средств в рамках Платежей за ЖКУ и Прочие услуги, совершаемых Плательщиком с использованием Приложения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>4.5. Правообладатель не является платежным агентом и не несет ответственности за производимые денежные операции, платежи и взаиморасчеты между Пользователем, Услугодателем, банками второго уровня и третьими лицами.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>4.6. Правообладатель не гарантирует отсутствие ошибок и сбоев в работе Приложения в отношении предоставления возможности безналичной оплаты. Выбор соответствующей формы оплаты производится самостоятельно Пользователем в интерфейсе Приложения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>4.7. Комиссия взимается Правообладателем в качестве вознаграждения за возможность использования функционала Приложения, реализующего возможность оплаты Платежных документов посредством Приложения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>4.8. Информация о комиссии и ее размере отображается в интерфейсе Сервиса в момент формирования и просмотра Платежного документа, а также при переходе на страницу подтверждения Платежа.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>4.9. Нажимая на кнопку &laquo;Оплатить&raquo; на странице подтверждения Платежа в интерфейсе Приложения, Пользователь подтверждает свое безоговорочное согласие с условиями Соглашения, включая условия использования функционала Приложения, реализующего возможность оплаты Платежных документов, с оплатой Сервисного сбора и его размером, отображаемым в интерфейсе Приложения, с итоговой суммой, подлежащей оплате, а также дает Правообладателю согласие на обработку своих персональных данных.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>4.10. После подтверждения Платежа на странице подтверждения Платежа в интерфейсе Приложения Пользователь перенаправляется на платежный шлюз банков второго уровня.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>4.11. Конфиденциальность сообщаемой персональной информации обеспечивается банками второго уровня.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>4.12. Вопросы и обращения Плательщиков, связанные с осуществлением переводов денежных средств, возвратов сумм ошибочных платежей и иные вопросы, касающиеся финансовых услуг, рассматриваются непосредственно банками второго уровня и Услугодателем.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>&nbsp;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:center;background:white;'><strong>5. ПРАВА И ОБЯЗАННОСТИ СТОРОН</strong></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.1. Правообладатель обязуется:</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.1.1. Предоставить Пользователю доступ к функциональным возможностям Приложения в порядке и на условиях, определенных Соглашением.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.1.2. Предоставлять Пользователю по его запросу информацию по вопросам функционирования Приложения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.1.3. Обеспечивать в рамках имеющейся технической возможности надлежащее функционирование Приложения, в том числе устранение технических проблем в разумные сроки.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.1.4. Доводить до сведения Пользователя информацию о способах осуществления оплаты по начислениям и Платежным документам, предоставляемым Пользователям в Приложении.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.1.5. Оказывать Пользователю необходимую информационную и Техническую поддержку при эксплуатации Приложения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.2. Правообладатель вправе:</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.2.1. По своему усмотрению без специального уведомления Пользователя вносить</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>изменения в Приложение.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;background:white;'>5.2.2. Проводить профилактические работы и при необходимости приостанавливать работу Приложения для проведения профилактических работ.<br>&nbsp;5.2.3. Направлять Пользователю информационные сообщения в том числе рекламного характера с использованием Приложения, push-уведомлений, SMS сообщений, а также сообщений, направленных на адрес электронной почты, по номеру телефона и адресам электронной почты, предоставленным Пользователем при Регистрации.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.2.4. Прекратить предоставление Пользователю доступа к Приложению в случае нарушения условий Соглашения и расторгнуть Соглашение в одностороннем порядке.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.3. Пользователь обязуется:</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.3.1. Использовать Приложение самостоятельно, исключительно в пределах тех полномочий, теми способами и в сроки, которые предусмотрены Соглашением.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.3.2. Обеспечивать конфиденциальность своего логина, пароля или иных регистрационных данных, используемые для доступа к Приложению и нести все риски, связанные с разглашением данных сведений третьим лицам.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.3.3. Не предпринимать ничего, что могло бы нанести какой-либо ущерб Правообладателю, нормальному функционированию Приложения или нарушить исключительные права Правообладателя, условия Соглашения или действующего законодательства.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.3.4. Не копировать Приложение или какую-либо его часть;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.3.5. Не изменять Приложение или какую-либо его часть;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.3.6. Не декомпилировать, не преобразовывать иным образом Приложение в исходный код или какую-либо его часть, не раскрывать иным способом исходный код Приложения;<br>&nbsp;5.3.7. Не видоизменять Приложение каким-либо образом или в какой-либо форме;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.3.8. Не использовать Приложение для создания аналогичного или конкурентного продукта или услуги, а также не использовать несанкционированные видоизмененные версии Приложения, включая создание подобных продуктов и/или конкурентного сервиса или получения несанкционированного доступа к Приложению;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.3.9. Не модифицировать (перерабатывать) Приложение каким-либо образом;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.3.10. Не фальсифицировать свой IP-адрес, адреса, используемые в других сетевых протоколах, при передаче данных;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.3.11. Не осуществлять действия, направленные на нарушение функционирования Приложения;<br>&nbsp;5.3.12. Не осуществлять действия, направленные на получение доступа к данным других пользователей Приложения;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.3.13. Не передавать образом права на использование Приложения, а также не уступать и не передавать права и обязанности по Соглашению третьим лицам.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.4. Пользователь признает и соглашается с тем, что все интеллектуальные права, в том числе исключительное право на Приложение, товарный знак, знак обслуживания и иные результаты интеллектуальной деятельности Правообладателя принадлежат Правообладателю.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.5. Пользователь подтверждает, что ознакомился со всеми функциональными свойствами и характеристиками Приложения. Пользователь несет риск несоответствия Приложения своим пожеланиям и потребностям.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>5.6. Пользователь гарантирует достоверность и актуальность сведений, предоставляемых Правообладателю при заключении и исполнении Соглашения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>&nbsp;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:center;background:white;'><strong>6. ОТВЕТСТВЕННОСТЬ</strong></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.1. Стороны несут ответственность за неисполнение или ненадлежащее исполнение условий Соглашения в соответствии с действующим законодательством Республики Казахстан.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.2. Правообладатель ни при каких обстоятельствах не несет ответственности за:</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- негативные последствия, являющиеся прямым или косвенным результатом действий/бездействия Пользователя и/или третьих лиц со стороны Пользователя;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- косвенные убытки и/или упущенную выгоду Пользователя и/или третьих сторон со стороны Пользователя вне зависимости от того, мог ли Правообладатель предвидеть возможность таких убытков или нет;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>- достоверность, законность и соответствие действительности уведомлений в Приложении инициированных Услугодателем либо третьими лицами</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.3. Правообладатель ни при каких обстоятельствах не несет ответственности за любые последствия, являющиеся прямым или косвенным результатом несоблюдением Пользователем требований о конфиденциальности логина, пароля и иных регистрационных данных, используемых для доступа к Приложению, а также действий любых третьих лиц в случае получения ими неправомерного доступа к Приложению или его отдельным функциональным возможностям, предоставления Правообладателю третьими лицами недостоверных сведений и данных.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.4. Правообладатель не несет ответственности за любые сбои, блокировки денежных средств или переводов, несвоевременные переводы денежных средств, нарушения в денежных расчетах между Пользователем и Поставщиком при совершении сделок или оплате ЖКУ и Прочих услуг, в случае если указанные денежные расчеты осуществлялись Пользователем посредством Приложения и представленных в нем платежных инструментов, предоставленных сторонними платежными системами, так как Правообладатель не является владельцем указанных платежных инструментов.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.5. Принимая условия настоящего Соглашения, Пользователь признает и соглашается, что Правообладатель не несет ответственности по договорам или обязательствам между Пользователем и Услугодателем, а также третьими лицами, а равно их нарушению, которые возникли, в рамках использования Пользователем Приложения. В том числе, Правообладатель не несет ответственности за сделки, совершенные между Пользователем и Услугодателем, а также третьими лицами в рамках использования Пользователем Приложения, так как Правообладатель не является стороной указанных сделок.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.6. Принимая условия настоящего Соглашения, Пользователь признает и соглашается, что Правообладатель не несет ответственности за любую информацию, размещаемую Пользователем, Услугодателем и третьими лицами в Приложении или направляемую в адрес Пользователя посредством push-уведомления, SMS сообщений, исходящих телефонных звонков, а также сообщений, направленных на адрес электронной почты, в том числе за ее актуальность, достоверность и соответствию действующему законодательству Республики Казахстан.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.7. В случае нарушения Пользователем любого из положений Соглашения и/или законодательства при использовании Приложения Правообладатель вправе в одностороннем внесудебном порядке приостановить доступ к Приложению до полного устранения Пользователем нарушений либо расторгнуть Соглашение путем уведомления об этом Пользователя с даты, указанной в таком уведомлении, любым способом по выбору Правообладателя, в том числе посредством Приложения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.8. Приложение предоставляется на условиях &laquo;как есть&raquo; (as is), то есть без гарантийных обязательств любого рода, включая отсутствие гарантий его пригодности для использования в определенных Пользователем целях, гарантий качества и работоспособности, соответствия функциональных возможностей целям и ожиданиям Пользователя.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.9. Правообладатель не несет ответственности за качество и функциональные особенности Приложения, как явные, так и предполагаемые.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.10. Ответственность за соблюдение порядка принятия решений по управлению объектом кондоминиума, корректности и законности вопросов, указанных в листах голосования, достоверности итоговых решений, а также корректности идентификации собственников и лиц имеющих право участия в голосовании возлагается исключительно на Услугодателя и Пользователя.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.11. Ответственность за хранение, обработку, использование и корректность персональных данных при действиях, связанных с обеспечением исполнения обязательств Пользователя по несению бремени содержания общего имущества перед Услугодателем, возлагается исключительно на Услугодателя.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.12. В случае нарушения настоящего Соглашения и условий использования Приложения Пользователь обязуется возместить Правообладателю ущерб, причиненный такими действиями.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.13. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по Соглашению, если неисполнение явилось следствием обстоятельств непреодолимой силы, возникших после присоединения Пользователя к Соглашению, которые Сторона не могла предотвратить доступными мерами, подтверждаемых уполномоченными организациями или государственными органами.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>6.14. К обстоятельствам непреодолимой силы относятся наводнения, пожары, землетрясения и иные явления природы, пандемия, война, военные действия, DDoS атаки, акты или действия органов власти и управления, в том числе банки второго уровня, решения уполномоченных государственных органов и исполнительной власти, а также других государственных органов, которые делают исполнение условий Договора невозможным для Сторон.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>&nbsp;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:center;background:white;'><strong>7. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</strong></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>7.1. Осуществляя действия по Регистрации в Приложении и начиная использовать Приложение или его отдельные функции, Пользователь безоговорочно принимает условия настоящего Соглашения в полном объеме без оговорок, исключений и изъятий. Если пользователь не согласен с каким-либо положением настоящего Соглашения, Пользователь не вправе использовать Приложение. Последующие ссылки Пользователя на несогласие с условиями настоящего Соглашения в случае начала использования Приложения не будут являться основаниями для признания настоящего Соглашения не заключенным.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>7.2. Соглашение может быть изменено и (или) дополнено Правообладателем в одностороннем порядке посредством размещения новой редакции Соглашения в Приложении без какого-либо специального (отдельного) уведомления Пользователя. Соглашение в новой редакции вступает в силу с момента опубликования в Приложении, если иное не предусмотрено новой редакцией Соглашения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>7.3. Пользователь обязуется самостоятельно отслеживать изменения условий Соглашения.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>7.4. В случае несогласия Пользователя с внесенными в Соглашение изменениями и (или) дополнениями, Пользователь имеет право в любой момент отказаться от использования Приложения посредством его удаления с мобильного устройства. Продолжая использовать Приложение, Пользователь безоговорочно принимает условия настоящего Соглашения в новой редакции в полном объеме без оговорок, исключений и изъятий.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>7.5. Соглашение может быть в любое время расторгнуто по инициативе любой из Сторон в уведомительном порядке. Соглашение будет считаться расторгнутым с даты, указанной в таком уведомлении.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>7.6. Все споры и разногласия, вытекающие из Соглашения и/или связанные с ним, Стороны будут стремиться разрешить посредством переговоров. Претензионный порядок рассмотрения споров является обязательным. Срок рассмотрения претензии &ndash; 10 (десять) календарных дней с момента получения претензии.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>7.7. Во всем, что не урегулировано Соглашением, Стороны руководствуются действующим Законодательством Республики Казахстан.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>7.8. Обращения, предложения и претензии физических и юридических лиц к Правообладателю, связанные с содержанием и функционированием Приложения, нарушениями прав и интересов третьих лиц, требований законодательства Республики Казахстан, а также для запросов уполномоченных государственных органов могут быть направлены на электронную почту: <a href="mailto:kozhakhmetov@aula.city"><span style="color:windowtext;">kozhakhmetov@aula.city</span></a></p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;background:white;'>&nbsp;</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:right;background:white;'>Редакция Пользовательского соглашения от &laquo;27&raquo; апреля 2022 года.</p>
    <p style='margin:0cm;margin-bottom:.0001pt;font-size:16px;font-family:"Times New Roman",serif;text-align:justify;'>&nbsp;</p>
</template>

<script>
import BackLogo from "@/assets/img/back-button.svg"
export default {
  components: {
    BackLogo
  }
}
</script>

<style scoped>
p {
  font-size: 14px;
}
span {
  font-size: 14px;
}
</style>