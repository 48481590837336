export default function(instance) {
	return {
		// * Пропуск по qr
		qr_in(params) {
			return instance({
				url: "/v2/users/qr",
				method: "post",
				params,
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
		// * Добавление логов
		qr_logs(params) {
			return instance({
				url: "/v2/users/qr/log",
				method: "post",
				params,
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
  }
}