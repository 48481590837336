import Vue from "vue";

const mixin = {
  data() {
    return {
      
    }
  },
	methods: {
    delayAnimationGo(destination) {
      setTimeout(() => {
        this.$router.push(`${destination}`)
      }, 150);
		},
    getFullImagePath(images) {
      let res = [];
      console.log('asd', images)
      console.log('Array.isArray(images)', Array.isArray(images))
      if(Array.isArray(images)) {
        for(let i = 0; i < images.length; i++) {
          if(images[i]?.path) {
            res.push(window.localStorage.env_api + '/' + images[i].path)
          } else {
            res.push(window.localStorage.env_api + '/' + images[i])
          }
        }
        console.log('res ima', res)
        return res
      }
    }
	},
};

Vue.mixin(mixin);