export default {
  state: {
    cordova: null
  },
  mutations: {
    setCordova(state, newCordova) {
      return state.cordova = newCordova
    },
  },
  actions: {
    getCordova(context, data) {
      context.commit("setCordova", data);
    }
  },
  getters: {
    Cordova(state) {
      return state.cordova;
    }
  }
}