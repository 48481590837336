<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon to="/requisitions")
      BackLogo
    v-app-bar-title.text-center(color="white") Создать заявку
  Spinner(v-if="!loaded")
  v-container(v-else class="pa-0 px-4")
    v-form(ref="form" lazy-validation)
      v-list-item.pa-0
        v-avatar.mr-3(size="36px")
          img(  
            v-if="profile.avatar"
            alt="Avatar"
            :src="avatar"
          )
          AvatarLogo(v-else)
        v-list-item-content
          v-select(
            v-model="form.apartment_id"
            placeholder="Выберите помещение"
            class="border-all mt-1"
            solo
            dense
            hide-details
            flat
            required
            :rules="rules.required"
            item-value="_id"
            item-text="house.apartment_address"
            :items="houses"
          )
            template(v-slot:item="{ item }")
              v-list-item-content
                span {{ item.type | getCatalogValue('room_types') }} 
                  span(v-if="item.apartment_number") №{{ item.apartment_number }}
                span(class="subtitle-2 font-weight-light grey--text") {{ item.house.apartment_address }}
            template(v-slot:selection="{ item }")
              span.py-2
                span {{ item.type | getCatalogValue('room_types') }} 
                  span(v-if="item.apartment_number") №{{ item.apartment_number }}
                p(class="subtitle-2 ma-0 font-weight-light grey--text") {{ item.house.apartment_address }}
      v-select(
        v-model="form.category_id"
        class="border-all"
        placeholder="Выберите категорию"
        solo
        dense
        flat
        hide-details
        item-text="name"
        item-value="_id"
        :rules="rules.required"
        :items="catalog.request_categories"
      )
      v-select(
        v-model="form.visibilite"
        class="border-all mt-3"
        placeholder="Укажите тип заявки"
        solo
        dense
        flat
        hide-details
        item-text="name"
        item-value="sys_name"
        :rules="rules.required"
        :items="catalog.request_visibility"
      )
      v-textarea(
        v-model="form.description"
        class="mt-3 desc"
        rows="3"
        placeholder="Опишите вашу проблему"
        solo
        outlined
        hide-details
        dense
        flat
        :rules="rules.required"
      )
      v-row.mt-1
        v-col(cols="4")
          label(for="input" class="custom-file-upload")
            CameraLogo
            p(class="text-caption ma-0 font-weight-light grey--text noselect") Добавить фото
          input(
            type="file" accept="image/*" ref="file" id="input" multiple
            @change="uploadFiles($event)"
          )
        v-col(v-for="(image, index) in pictures" :key="index" cols="4" class="position-relative")
          img.image-card(:src="image")
          v-btn.remove-btn(icon color="#fff" @click.stop="removeImg(index)")
            v-icon(style="font-size: 18px;") mdi-close
      v-btn.mt-3(block color="primary" :loading="loading" @click="createReq") Создать
</template>

<script>
import { mapGetters } from "vuex";
import BackLogo from "@/assets/img/back-button.svg"
import AvatarLogo from "@/assets/img/avatar.svg"
import CameraLogo from "@/assets/img/camera.svg"
export default {
  data() {
    return {
      houses: [],
      loaded: false,
      loading: false,
      image: null,
      pictures: [],
      rules: {
				required: [v => !!v || ""],
			},
      form: {
        apartment_id: '',
        category_id: '',
        visibilite: '',
        description: '',
        images: []
      }
    }
  },
  computed: {
    ...mapGetters({
      catalog: "catalog/GET_CATALOG",
      profile: "getStorageProfile",
    }),
    avatar() {
      return (`${window.localStorage.env_api}/${this.profile.avatar}`)
    },
  },
  methods: {
    uploadFiles(el) {
      console.log('el', el.target.files[0])
      for(let i = 0; i < el.target.files.length; i++) {
        this.pictures.push(URL.createObjectURL(el.target.files[i]))
        this.form.images.push(el.target.files[i])
      }
    },
    async createReq() {
      if (!this.$refs.form.validate()) {
				return;
			}
      this.loading = true
      const data = { ...this.form };
      console.log('data', data)
      const formData = data.toFormData();
      try {
        const res = await this.$api.requisitions.add_requisition(formData);
        console.log('res', res)
        if(res?.success) {
          this.$root.snackbar.show({ color: "success", message: res.success || 'Заявка успешно создана' }); 
          this.$router.push('/requisitions')
        }
        if(res?.color == 'error') {
          this.$root.snackbar.show({ color: "red", message: res.message || 'Произошла ошибка, повторите попытку!' }); 
        }
        this.loading = false
      } catch (error)  {
        console.log('err', error)
      }
    },
    removeImg(index) {
      this.pictures.splice(index, 1)
    },
    async loadHouses() {
      this.loaded = await this.$api.houses.load_houses();
      this.houses = this.loaded
    }
  },
  async beforeMount() {
		this.loadHouses()
	},
  components: {
    BackLogo,
    CameraLogo,
    AvatarLogo
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #d8dbe0;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
  width: 110px;
  height: 80px;
  padding: 15px 2px;
}

.image-card {
  border: 1px solid #d8dbe0;
  border-radius: 8px;
  width: 110px;
  height: 80px;
}

.remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #2EB85C !important;
  width: 20px;
  height: 20px;
}


</style>

<style>
.desc.v-text-field--outlined fieldset {
  border: 1px solid #d8dbe0 !important;
}
.desc.v-input--is-focused.v-text-field--outlined fieldset {
  border: 1px solid #2EB85C !important;
}
.desc.error--text.v-text-field--outlined fieldset {
  border: 1px solid #ff5252 !important;
}
</style>