import axios from "axios";
import store from "@/store";
import { api } from "@/helpers/mobile";

if(!window.localStorage.getItem('env_api')) {
	console.log('no API', window.localStorage)
	api.setData('env_api', 'https://api.aula.city')
}
const instance = axios.create({
	baseURL: window.localStorage.env_api,
});

instance.interceptors.request.use(
	config => {
		const token = window.localStorage.token;
		if (token) {
			config.headers["Authorization"] = token;
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	response => {
		if (response.status !== 200) {
			return Promise.reject(new Error(response.data.error || "Ошибка"));
		}
		return response;
	},
	error => {
		if (error.response?.status === 401) {
			store.dispatch("logout");
		}
		return Promise.reject({
			error: error,
			color: "error",
			message: error.response?.data.error || error,
		});
	}
);

export default instance;
