import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ruLocale from '@/locales/ru';
import kzLocale from '@/locales/kz';
import enLocale from '@/locales/en';

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: 'ru',
});

i18n.setLocaleMessage('ru', ruLocale);
i18n.setLocaleMessage('kz', kzLocale);
i18n.setLocaleMessage('en', enLocale);
i18n.locale = 'ru'

export default i18n;
