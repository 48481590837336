<template lang="pug">
div
  v-app-bar.center-bar(elevation="0" color="primary" dark app)
    v-app-bar-title.text-center(color="white") Заявки
    //- v-spacer
    //- v-btn(icon)
    //-   v-icon mdi-filter-outline

  v-tabs(fixed-tabs v-model="tabs")
    v-tab(href="#tab-1" class="text-none") Индивидуальные
    v-tab(href="#tab-2" class="text-none") Публичные
  v-tabs-items(v-model="tabs" v-if="items.length")
    v-tab-item(value="tab-1")
      vue-loadmore(
        :on-refresh="onRefresh" 
        :on-loadmore="onLoad"
        pulling-text="Потяните вниз"
        refresh-text="Обновление"
        loosing-text="Обновить"
        loading-text="Загрузка"
        finished-text="Нет данных"
        :show-success-text="false"
        :finished="finished")
        Individual(v-for="(item, i) in items" :items="item" :key="i")
    v-tab-item(value="tab-2")
  Spinner(v-else)
</template>

<script>
import { mapGetters } from "vuex";
import Individual from '../../components/requisitions/individual.vue'
export default {
  data() {
    return {
      tabs: null,
      loaded: false,
      items: [],
      finished: false,
      page: 1
    }
  },
  computed: {
		...mapGetters({
      catalog: "catalog/GET_CATALOG",
    }),
  },
  methods: {
    async onRefresh(done) {
      this.list = [];
      this.page = 1;
      this.finished = false;
      const res = await this.$api.requisitions.load_requisitions(this.page)
      this.items = res.data
      done();
    },

    async onLoad(done) {
      console.log('asd')
      this.page = ++this.page
      console.log('res', this.items)
      const res = await this.$api.requisitions.load_requisitions(this.page)
      this.items.push(res.data)
      console.log('res', this.items)
      if(res.pages == this.page) this.finished = true;
      done();
    },
  },
  async created() {
    const res = await this.$api.requisitions.load_requisitions(this.page)
    this.items.push(res.data)
    console.log('this.', this.items)
  },
  components: {
    Individual,
  }
}
</script>

<style scoped>
.ft-12 {
  font-size: 12px;
}
.ft-14 {
  font-size: 14px;
}
.v-card {
  transition: opacity .4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.85;
 }
</style>