<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon to="/menu" plain)
      BackLogo
    v-app-bar-title.text-center {{ $t('settings.settingsAppBarTitle') }}
  v-container
    span(class="subtitle-1 font-weight-regular mb-2") {{ $t('settings.settingsLanguage') }}
    v-select(
      v-model="$i18n.locale"
      placeholder="Язык"
      class="border-all mt-1 lang"
      solo
      dense
      hide-details
      flat
      required
      item-value="sys_name"
      item-text="name"
      :items="langs"
    )
    v-checkbox(v-model="notify" :label="$t('settings.settingsNotifications')")
    v-list.pa-0
      v-list-item(@click="delayAnimationGo('/terms')")
        v-list-item-content.pa-0
          v-list-item-title.ft-14.primary--text {{ $t('settings.settingsTermsOfUse') }}
        v-list-item-icon
          v-icon mdi-chevron-right
      v-list-item(@click="delayAnimationGo('/policy')")
        v-list-item-content.pa-0
          v-list-item-title.ft-14.primary--text {{ $t('settings.privacyPolicySetting') }}
        v-list-item-icon
          v-icon mdi-chevron-right
  v-divider(style="border-width: initial;")
  v-container
    v-card(elevation="0" @click="delayAnimationGo('/menu/settings/delete')")
      v-list.pa-0
        v-list-item
          v-list-item-content.pa-0
            v-list-item-title.ft-14.primary--text {{ $t('settings.deleteAccount') }}
          v-list-item-icon
            v-icon mdi-chevron-right
  v-footer(padless app bottom fixed color="rgb(255, 0, 0, 0)")
    v-col(class="text-center" cols="12")
      p.font-weight-light {{ $t('settings.suggestionText') }} info@aula.kz
      h4.font-weight-regular {{ $t('settings.settingsVersion') }} {{ version }}
</template>

<script>
import { mapGetters } from "vuex";
import BackLogo from "@/assets/img/back-button.svg"
export default {
  data() {
    return {
      language: null,
      notify: null,
      langs: [
        {sys_name: 'ru', name: 'Русский'},
        {sys_name: 'kz', name: 'Қазақ тілі'},
        {sys_name: 'en', name: 'English'},
      ]
    }
  },
  computed: {
    ...mapGetters({
      version: "VersionApi",
    }),
  },
  components: {
    BackLogo
  }
}
</script>

<style>
.v-menu__content .v-list-item .v-list-item__title {
  font-weight: 400;
}
</style>