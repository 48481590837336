<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon to="/menu")
      BackLogo
    v-app-bar-title.text-center О доме
  div(v-if="!items.length && !loaded.length")
    p.ft-18.absolute-center.text-center Для подключения дома нажмите кнопку Добавить дом и следуйте инструкции
    v-footer.mb-3(app bottom fixed color="rgb(255, 0, 0, 0)")
      v-btn(block color="primary" to="house/add") Добавить дом
  template(v-else-if="items.length")
    v-card(v-for="(item, i) in items" :key="i" elevation="0")
      v-card-subtitle {{ item.type | getCatalogValue("room_types") }}
      v-card-text
        v-list-item
          v-list-item-icon
            v-icon(color="primary") mdi-home
          v-list-item-content
            v-list-item-title.text-wrap {{ item.house.apartment_address }}, {{ item.apartment_number }}
            v-list-item-subtitle
              span(v-if="item.porch_number") подъезд {{ item.porch_number }}
              span(v-if="item.porch_number && item.floor_number") , 
              span(v-if="item.floor_number") этаж {{ item.floor_number }}
          v-list-item-action.ma-0
            v-list-item-action-text
              v-menu(top offset-y close-on-content-click)
                template(v-slot:activator="{ on, attrs }")
                  v-btn(icon v-bind="attrs" v-on="on" @click.prevent)
                    v-icon mdi-dots-vertical
                v-list.pa-0
                  v-list-item(@click="deleteApartment(item)")
                    v-list-item-title Удалить
      v-divider
      v-list-item(:to="'/houses/' + item._id")
        v-list-item-content
          v-list-item-title Прописанные жители ({{ item.users_count }} чел.)
        v-list-item-icon
          v-icon mdi-chevron-right  
      v-divider
      v-list-item
        v-list-item-content
          v-list-item-title Орган управления
        v-list-item-icon
          v-icon mdi-chevron-right  
      v-divider(style="border-width: initial;")
    v-fab-transition
      v-btn(
        to="/house/add"
        bottom
        right
        fab
        dark
        fixed 
        color="primary"
        style="bottom:65px;"
      )
        v-icon mdi-plus
  Spinner(v-else)
</template>

<script>
import BackLogo from "@/assets/img/back-button.svg"
export default {
  data() {
    return {
      items: [],
      loaded: 'wait'
    }
  },
  methods: {
    async deleteApartment(item) {
      try {
        const res = await this.$api.houses.delete_apartment(item.registration_id);
        this.$root.snackbar.show({ color: "success", message: res.success || 'Объект удален успешно' });
        this.loadData()
      } catch (error) {
        console.log('err0', error)
      }
      
    },
    async loadData() {
      this.items = []
      this.loaded = await this.$api.houses.load_houses();
      this.items = this.loaded
    }
  },
  async beforeMount() {
		this.loadData()
	},
  components: {
    BackLogo
  }
}
</script>