export default function(instance) {
	return {
		// * Получение статистики по заявкам
		load_requisitions(page) {
			return instance({
				url: `/v1/user/request/?visibilite=individual&page=${page}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},
		load_requisition(id) {
			return instance({
				url: `/v1/news_feed/current/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},
		add_requisition(data) {
			return instance({
				url: `/v1/news_feed/request`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(error => error);
		},
		load_comments(id, page) {
			return instance({
				url: `/v2/comment/?record_id=${id}&page=${page}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},
		set_comment(data) {
			return instance({
				url: `/v2/comment/request`,
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(error => error);
		},
  }
}