export default function(instance) {
	return {
		// * Регистрация
		registration(phone) {
			return instance({
				url: "/v2/users/registration",
				method: "post",
				params: { phone },
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
		// * Авторизация
		autorization(phone, password) {
			return instance({
				url: "/v2/users/authorization",
				headers: { "Content-Type": "application/json" },
				method: "get",
				params: { phone, password },
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
		// Генерация пароля
		generate_password(params) {
			return instance({
				url: "/v1/user/generate-password/",
				method: "post",
				params,
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
		// * Получение профиля
		load_data() {
			return instance({
				url: "/v1/user",
				method: "get",
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
		// * Редактирование профиля
		edit_data(data) {
			return instance({
				url: "/v1/user",
				method: "post",
				data,
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
		// * Получение минимальной версии апи
		get_minimal_version() {
			return instance({
				url: "/version/minimal",
				method: "get",
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
		// * Добавление токена android
		set_android_token(token) {
			return instance({
				url: `/v1/user/token/android/${token}`,
				method: "post",
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
		// * Добавление токена ios
		set_ios_token(token) {
			return instance({
				url: `/v1/user/ios/${token}`,
				method: "post",
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
  }
}