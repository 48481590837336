<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(v-if="profile.main_apartment" icon to="/houses")
      BackLogo
    v-btn.pa-0(v-else text @click="logout" small) Выйти
    v-spacer
    v-app-bar-title.text-center(color="white") Добавить объект
    v-spacer
  v-container
    p.ma-0.mb-2 Укажите в какой городе вы хотите добавить объект
    v-select(
      v-model="form.city"
      placeholder="Укажите город"
      class="border-all mt-1"
      solo
      dense
      hide-details
      flat
      :disabled="loading"
      :loading="loading"
      item-value="_id"
      item-text="name"
      :items="catalog.cities"
    )
    p.mt-3.ma-0.mb-2 Укажите ваш адрес {{ form.apartment_id }}
    v-autocomplete(
      v-model="form.apartment"
      class="border-all"
      placeholder="Укажите адрес дома"
      solo
      dense
      flat
      clearable
      hide-details
      return-object
      :disabled="loading"
      :loading="loading"
      item-text="apartment_address"
      item-value="_id"
      :items="houses"
      no-data-text="Нет зарегистрированных домов с таким адресом"
    )
    p.text-caption Выберите адрес из списка
    //- p.mt-4 Укажите ваш адрес
    //- div(@click="sheet = true")
    //-   v-text-field(
    //-     class="border-all mt-1 pl-3"
    //-     value="Адрес"
    //-     prepend-icon="mdi-magnify"
    //-     disabled
    //-     hide-details
    //-     solo
    //-     dense
    //-     flat
    //-   )
    //- v-bottom-sheet.address-sheet(v-model="sheet")
    //-   v-sheet.sheet(rounded)
    //-     v-container
    //-       p.mt-2 Укажите адрес дома
    //-       v-autocomplete(
    //-         v-model="form.apartment_id"
    //-         class="border-all"
    //-         solo
    //-         dense
    //-         flat
    //-         clearable
    //-         hide-details
    //-         :disabled="loading"
    //-         item-text="apartment_address"
    //-         item-value="_id"
    //-         :items="houses"
    //-         no-data-text="Нет зарегистрированных домов с таким адресом"
    //-       )
    //-       p.text-caption Выберите адрес из списка
    //-       div.load-address(v-if="loading")
    //-         Spinner
    v-select(
      v-model="form.type"
      placeholder="Вид объекта"
      class="border-all mt-5"
      solo
      dense
      hide-details
      flat
      return-object
      item-text="name"
      :items="catalog.room_types"
    )
    template(v-if="form.type")
      div.mt-5(v-if="form.type.sys_name == 'apartment'")
        v-text-field(
          v-model="form.apartment_number"
          class="border-all"
          placeholder="Номер квартиры"
          hide-details
          solo
          dense
          flat
        )
        v-row.mt-2
          v-col(cols="6")
            v-text-field(
              v-model="form.porch_number"
              class="border-all"
              placeholder="Подъезд"
              hide-details
              solo
              dense
              flat
            )
          v-col(cols="6")
            v-text-field(
            v-model="form.floor_number"
            class="border-all"
            placeholder="Этаж"
            hide-details
            solo
            dense
            flat
          )
      div.mt-5(v-else-if="form.type.sys_name == 'parking-space'")
        v-text-field(
          v-model="form.parkingNumber"
          class="border-all mt-5"
          placeholder="Номер парковочного места"
          hide-details
          solo
          dense
          flat
        )
      div.mt-5(v-else-if="form.type.sys_name == 'non-residential-premises'")
        v-text-field(
          v-model="form.apartment_number"
          class="border-all mt-5"
          placeholder="Номер НП"
          hide-details
          solo
          dense
          flat
        )
        v-text-field(
          v-model="form.activity"
          class="border-all mt-5"
          placeholder="Вид деятельности"
          hide-details
          solo
          dense
          flat
        )
        v-text-field(
          v-model="form.name"
          class="border-all mt-5"
          placeholder="Наименование НП"
          hide-details
          solo
          dense
          flat
        )
      v-select(
        v-model="form.affilation_id"
        placeholder="Я являюсь"
        class="border-all mt-5"
        solo
        dense
        hide-details
        flat
        item-value="_id"
        item-text="name"
        :items="catalog.apartments_affiliations"
      )
    v-btn.mt-3(block color="primary" :disabled="disabledButton" @click="addObject") Добавить
</template>

<script>
import { mapGetters } from "vuex";
import BackLogo from "@/assets/img/back-button.svg"
export default {
  data() {
    return {
      sheet: false,
      loading: true,
      houses: [],
      form: {
        city: '5f4cda300796c90b114f5548',
        address: null,
        type: null,
        apartment: null,
        apartment_number: null,
        porch_number: null,
        floor_number: null,
        affilation_id: null,
        parkingNumber: null,
        name: null,
        activity: null,
      }
    }
  },
  watch: {
    'form.city' () {
      console.log('change')
      this.houses = []
      this.getHouses()
    }
  },
  computed: {
		...mapGetters({
      catalog: "catalog/GET_CATALOG",
      profile: "getStorageProfile"
    }),
    disabledButton() {
      if(this.form.type?.sys_name === 'apartment') {
        if(this.form.city && this.form.apartment && this.form.apartment_number && this.form.floor_number && this.form.porch_number && this.form.affilation_id) {
          return false
        } else {
          return true
        }
      } else if(this.form.type?.sys_name === 'parking-space') {
        if(this.form.city && this.form.apartment && this.form.affilation_id && this.form.parkingNumber) {
          return false
        } else {
          return true
        }
      } else if(this.form.type?.sys_name === 'non-residential-premises') {
        if(this.form.city && this.form.apartment && this.form.affilation_id && this.form.apartment_number && this.form.name) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
  },
  methods: {
    addObject() {
      if(this.form.type?.sys_name === 'apartment') {
        this.addApartment()
      } else if(this.form.type?.sys_name === 'parking-space') {
        this.addParking()
      } else if(this.form.type?.sys_name === 'non-residential-premises') {
        this.addNonResidential()
      }
    },
    async addApartment() {
      let params = {
        city_id: this.form.city,
        affilation_id: this.form.affilation_id,
        floor_number: this.form.floor_number,
        porch_number: this.form.porch_number,
        apartment_number: this.form.apartment_number,
        apartment_address: this.form.apartment.apartment_address,
        d_room_type_id: 1,
        _id: this.form.apartment._id
      }
      try {
        const res = await this.$api.houses.add_apartment(params)
        if (res.message) {
          this.$root.snackbar.show({ color: res.color || "error", message: res.message || 'Произошла ошибка, повторите попытку!' });
        }
        if(!this.profile.main_apartment) {
          await this.$store.dispatch('fetchProfile')
          window.location.assign(`/#/houses`);
        } else {
          setTimeout(() => this.$router.push('/houses'), 300);
        }
        
      } catch (error) {
        console.log('error', error)
      }
    },
    async addParking() {
      let params = {
        city_id: this.form.city,
        affilation_id: this.form.affilation_id,
        apartment_number: this.form.parkingNumber,
        apartment_address: this.form.apartment.apartment_address,
        d_room_type_id: 3,
        _id: this.form.apartment._id
      }
      try {
        const res = await this.$api.houses.add_parking(params)
        if (res.message) {
          this.$root.snackbar.show({ color: res.color || "error", message: res.message || 'Произошла ошибка, повторите попытку!' });
        }
        if(!this.profile.main_apartment) {
          await this.$store.dispatch('fetchProfile')
          window.location.assign(`/#/houses`);
        } else {
          setTimeout(() => this.$router.push('/houses'), 300);
        }
        
      } catch (error) {
        console.log('error', error)
      }
    },
    async addNonResidential() {
      let params = {
        city_id: this.form.city,
        name: this.form.name,
        activity: this.form.activity,
        affilation_id: this.form.affilation_id,
        apartment_number: this.form.apartment_number,
        apartment_address: this.form.apartment.apartment_address,
        d_room_type_id: 2,
        _id: this.form.apartment._id
      }
      try {
        const res = await this.$api.houses.add_non_residential(params)
        if (res.message) {
          this.$root.snackbar.show({ color: res.color || "error", message: res.message || 'Произошла ошибка, повторите попытку!' });
        }
        if(!this.profile.main_apartment) {
          await this.$store.dispatch('fetchProfile')
          window.location.assign(`/#/houses`);
        } else {
          setTimeout(() => this.$router.push('/houses'), 300);
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    async getHouses() {
      this.loading = true
      this.houses = await this.$api.houses.load_houses_by_city(this.form.city);
      this.loading = false
    },
    logout() {
      this.$store.dispatch('logout')
      this.$router.push('/login')
    }
  },
  async beforeMount() {
		this.houses = await this.$api.houses.load_houses_by_city(this.form.city);
    this.loading = false
	},
  components: {
    BackLogo
  }
}
</script>

<style scoped>
.sheet {
  height: 60vh;
}

.address-sheet {
  height: 100%;
}

.load-address {
  height: 40vh;
  position: relative;
}
</style>