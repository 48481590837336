<template lang="pug">
div.primary.white--text.slider-component
  h2.font-weight-regular.mb-5(style="padding-left: 40px;") {{ title }}
  div.main-slider
    div(class="swiper" ref="swiper")
      div.swiper-wrapper
        template(v-for="(item, i) in data")
          div.swiper-slide.white.black--text
            div.pa-3
              p.text-bold.ma-0.mb-1 {{ item.name }}
              p {{ item.text }}
              v-row
                v-col
                  p.ma-0.text-caption.grey--text {{ item.address }}
                v-col
                  p.ma-0.text-caption.grey--text {{ item.created_at }}
      div.swiper-pagination
  //- swiper(
  //-   ref="swiper"
  //-   :slides-per-view="1"
  //-   :space-between="10"
  //-   @swiper="onSwiper"
  //- )
  //-   swiper-slide Slide 1
  //-   swiper-slide Slide 2
</template>

<script>
import Swiper, { Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.min.css'
export default {
  props: ['title', 'data'],
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    }
  },
  mounted() {
    const SECOND = 500 // milliseconds

    // init Swiper:
    new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Pagination, Autoplay],

      loop: false,
      spaceBetween: 30,
      slidesPerView: 1.2,
      slideToClickedSlide: true,
      // autoplay: {
      //   delay: 3 * SECOND,
      //   disableOnInteraction: false,
      // },
      speed: 1 * SECOND,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },

      // Events
      on: {
        slideChange: (swiper) => {
          console.log('change')
          this.activeIndex = swiper.realIndex
        },
      },
    })
  },
}
</script>

<style scoped>
.slider-component {
  max-width: 600px;
  height: 270px;
}

.main-slider {
  display: flex;
  align-items: center;
}

.swiper {
  height: 200px;
  padding-bottom: 35px;
  padding-left: 40px;
}

.swiper-slide {
  border-radius: 12px;
}

.swiper-horizontal > .swiper-pagination {
    position: absolute;
    bottom: 0;
}

</style>

<style>
.swiper-pagination-bullet-active {
  background-color: #F9C962;
}
</style>