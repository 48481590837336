export default function(instance) {
	return {
		// * Получение голосований пользователя
		load_votes() {
			return instance({
				url: `/v2/users/ksk/votes`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},
		// * Получение только архивных голосований
		load_archive_votes() {
			return instance({
				url: `/v2/users/ksk/votes?is_archive=1`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},
		// * Получение голосования по айди
		get_vote(id) {
			return instance({
				url: `/v2/users/ksk/votes/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},
  }
}