<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon to="/requisitions" plain)
      BackLogo
    v-app-bar-title.text-center(color="white" v-if="loaded") Индивидуальная №{{ Requisition.id }}
  div(v-if="loaded")
    v-container
      v-row
        v-col(cols="4")
          span.text-bold(:class="getColorType(Requisition.request_type_id)") {{ Requisition.request_type_id | getCatalogValue("request_types") }}
          p.grey--text.ma-0 {{ Requisition.created_at | timestamp(true, { hideTime: true }) }}
        v-col(cols="1")
          v-divider(vertical)
        v-col(cols="7")
          p.ma-0 Ваша заявка находится на рассмотрении
    v-divider
    v-container
      v-row(
        align="center"
        class="spacer mt-2"
        no-gutters
      )
        v-col.text-center(
          cols="2"
          sm="2"
          md="1"
        )
          v-avatar(
            size="36px"
          )
            img(  
              v-if="Requisition.user"
              alt="Avatar"
              :src="avatar"
            )
            AvatarLogo(v-else)
        v-col(
          sm="5"
          md="3"
          cols="6"
        )
          strong(v-html="Requisition.appartment")
          p.grey--text {{ Requisition.apartment.house.apartment_address }}
        v-col.text-center(cols="4")
          v-btn(text color="primary" small) Редактировать
      v-row.mt-2(
        no-gutters 
        align="center"
        class="spacer"
      )
        v-col(cols="10")
          strong.ml-2 {{ Requisition.category_id | getCatalogValue('request_categories') }}
          p.ml-2 {{ Requisition.description }} 
      div(v-if="Requisition.images.length")
        Viewer(:images="getFullImagePath(Requisition.images)")
      v-divider(v-else)
      v-row.text-center
        v-col(cols="4")
        v-col(cols="4")
          Chat.pt-2(:roomName="$options.filters.getCatalogValue(Requisition.apartment.type_id, 'room_types') + ' №' + Requisition.apartment.apartment_number" :room_id="Requisition._id")
        v-col.d-flex.align-center.justify-end(cols="4")
          span.pt-2(
            class="grey--text ft-12"
          ) {{ Requisition.created_at | timestamp(true, { hideTime: true }) }}     
    v-divider(style="border-width: initial;")
    div.text-center.mt-2
      v-dialog(
        v-model="dialog"
        persistent
        max-width="290"
      )
        template(v-slot:activator="{ on, attrs }")
          v-btn(text class="text-none" color="red" v-bind="attrs" v-on="on") Отменить заявку
        v-card
          v-card-title(class="text-h6") Отменить заявку?
          v-card-actions
            v-spacer
            v-btn(color="grey lighten-1" text @click="dialog = false" ) Нет
            v-btn(color="green lighten-1" text @click="dialog = false" ) OK
  Spinner(v-else)
  
</template>

<script>
import { mapGetters } from "vuex";
import BackLogo from "@/assets/img/back-button.svg"
import Chat from '@/components/widgets/Chat.vue'
import AvatarLogo from "@/assets/img/avatar.svg"
import Viewer from '@/components/widgets/Viewer.vue'
export default {
  data() {
    return {
      loaded: false,
      dialog: false
    }
  },
  watch: {
    'Requisition' () {
      this.loaded = true
    }
  },
  computed: {
    ...mapGetters({
      catalog: "catalog/GET_CATALOG",
      Requisition: "Requisition", 
      Token: "Token",
    }),
    avatar() {
      return (`${window.localStorage.env_api}/${this.Requisition.user.avatar}`)
    } 
  },
  methods: {
    getImage(path) {
      return (`${window.localStorage.env_api}/${path}`)
    },
    getColorType(el) {
      let type = this.catalog.request_types.find((e => e._id == el))
			switch(type.sys_name) {
				case 'new':
					return 'blue--text'
				case 'accepted':
					return 'warning--text'
				case 'done':
					return 'success--text'
				case 'in_work':
					return 'purple darken-2--text'
				case 'canceled':
					return 'red--text'
				case 'rejected':
					return 'red--text'
				case 'overdue':
					return 'red--text'
				case 'canceled_by_user':
					return 'red--text'
				case 'not_done':
					return 'black-bold'
			}
    },
  },
  created() {
    this.$store.dispatch('getReqItem', {id: this.$route.params.id})
  },
  components: {
    AvatarLogo,
    Viewer,
    Chat,
    BackLogo
  }
}
</script>

<style scoped>
.theme--dark.v-btn--active::before {
  opacity: 0;
}
</style>