<template>
	<v-snackbar
    class="notification"
		v-model="showNotification"
		color="primary"
		:timeout="timer"
		top
		text
    @click.native="toDirect()"
	>
		<span>{{ message }}</span>
		<template v-slot:action="{ attrs }">
			<v-btn color="black" text v-bind="attrs" @click="showNotification = false"> 
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	name: "Snackbar",
  props: ['data'],
	data() {
		return {
			showNotification: false,
			message: "",
			color: "success",
			icon: "mdi-check",
			timer: 3000,
		};
	},
  watch: {
    'data'() {
      console.log('DATA here', JSON.stringify(this.data.gсm))
      if(this.data.gcm) {
        this.showNotification = true
        this.message = this.data.gcm.body
      }
      
    }
  },
	methods: {
		show(data) {
			if (this.showSnackbar) {
				this.showSnackbar = false;
				setTimeout(() => this.setData(data), 300);
			} else {
				this.setData(data);
			}
		},
    toDirect() {
      console.log('asdasd', this.data?.type_id)
      if(this.data?.type_id == 3) {
        this.$router.push('/requisitions/' + this.data.request_id)
        this.showNotification = false
      }
    },
		setData(data) {
			this.showNotification = true;
			this.message = data.message || 'missing "message".';
			this.color = data.color || "success";
			this.timer = data.timer || 3000;
			this.icon = data.icon || null;
		},
	},
};
</script>

<style>
.notification .v-snack__wrapper {
	margin-top: 50px;
}
</style>