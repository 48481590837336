<template>
  <v-bottom-navigation
    background-color="overlay"
    grow
    app
    color="primary"
  >
    <v-btn v-for="(item, i) in items" :to="item.route" class="px-0" :key="i">
      <span class="pt-1">{{item.title}}</span>
      <component :is="item.icon" class="bottom-icon"></component>
    </v-btn>
  </v-bottom-navigation>
  <!-- <v-bottom-navigation
    :value="value"
    color="primary"
    app
    fixed
    grow
  >
    <v-btn to='/' @click.native="scrollToTop" active-class="" >
      <span>{{ $t('bottom-nav.main') }}</span>

      <v-icon>mdi-home-outline</v-icon>
    </v-btn>

    <v-btn to='/requisitions' @click.native="scrollToTop">
      <span>{{ $t('bottom-nav.request') }}</span>

      <v-icon>mdi-note-text-outline</v-icon>
    </v-btn>
    <v-btn to='/qr' @click.native="scrollToTop">
      <span>{{ $t('bottom-nav.qr') }}</span>

      <v-icon>mdi-qrcode-scan</v-icon>
    </v-btn>
    <v-btn to='/services' @click.native="scrollToTop">
      <span>{{ $t('bottom-nav.services') }}</span>

      <v-icon>mdi-brush-variant</v-icon>
    </v-btn>

    <v-btn to='/menu' @click.native="scrollToTop">
      <span>{{ $t('bottom-nav.menu') }}</span>

      <v-icon>mdi-menu</v-icon>
    </v-btn>
  </v-bottom-navigation> -->
</template>

<script>
import menuIcon from "@/assets/img/bottom-menu.svg"
import homeIcon from "@/assets/img/bottom-house.svg"
import qrIcon from "@/assets/img/bottom-qr.svg"
import marketIcon from "@/assets/img/bottom-market.svg"
import profileIcon from "@/assets/img/bottom-profile.svg"
export default {
  data: function () {
    return {
      value: 0 ,
      items: [
        {id: 1, title: this.$i18n.t('bottom-nav.menu'), route: '/', icon: menuIcon},
        {id: 2, title: this.$i18n.t('bottom-nav.house'), route: '/houses', icon: homeIcon},
        {id: 3, title: this.$i18n.t('bottom-nav.qr'), route: '/qr', icon: qrIcon},
        {id: 4, title: this.$i18n.t('bottom-nav.services'), route: '/services', icon: marketIcon},
        {id: 5, title: this.$i18n.t('bottom-nav.profile'), route: '/menu', icon: profileIcon},
      ]
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  components: {
    menuIcon,
    homeIcon,
    qrIcon,
    marketIcon,
    profileIcon
  }
}
</script>

<style scoped>
.v-btn {
  height: auto !important;
}
.v-btn--active .bottom-icon {
  filter: invert(52%) sepia(80%) saturate(400%) hue-rotate(87deg) brightness(96%) contrast(92%);
}
</style>