// Axios interceptor
import instance from "./instance";

import qrModule from "./qr";
import userModule from "./user";
import votesModule from './votes'
import housesModule from "./houses";
import requisitionsModule from "./requisitions";
import notificationsModule from "./notifications";


export default {
	qr: qrModule(instance),
	user: userModule(instance),
	votes: votesModule(instance),
	houses: housesModule(instance),
	requisitions: requisitionsModule(instance),
	notifications: notificationsModule(instance),
};