<template lang="pug">
v-container
  v-row
    v-col(cols="6")
      p.grey--text Адрес
    v-col(cols="3")
      p.grey--text Вопросы
    v-col(cols="3")
      p.grey--text Срок
  v-card.px-2.my-1(v-for="(item, i) in data" :key="i" :to="'votes/' + item._id")
    v-row
      v-col(cols="6")
        span {{ item.house.apartment_address }}
      v-col.text-center(cols="3")
        span {{ item.questions.length }}
      v-col(cols="3")
        span {{ item.time_vote | timestamp }}
</template>

<script>
export default {
  props: ['data'],
}
</script>