<template lang="pug">
v-container
  template(v-for="(item, index) in items")
    v-hover(v-slot="{ hover }")
      v-card.mb-7.req-card(:key="index")
        v-card(elevation="0" :to="'/requisitions/' + item._id")
          v-row(
            align="center"
            class="spacer mt-2"
            no-gutters
          )
            v-col.text-center(
              cols="2"
              sm="2"
              md="2"
            )
              v-avatar(
                size="36px"
              )
                img(  
                  v-if="item.user?.avatar"
                  alt="Avatar"
                  :src="avatar(item)"
                )
                AvatarLogo(v-else)
            v-col(
              sm="8"
              md="8"
              cols="8"
            )
              p.ma-0.text-bold Квартира №{{ item.apartment.apartment_number }} 
                span(v-if="item.apartment.porch_number") под. {{ item.apartment.porch_number }}
              p.grey--text {{ item.apartment.house.apartment_address }}
            v-col.text-center(cols="2")
              v-card-actions
                v-menu(top offset-y close-on-content-click)
                  template(v-slot:activator="{ on, attrs }")
                    v-btn(icon v-bind="attrs" v-on="on" @click.prevent)
                      v-icon mdi-dots-vertical
                  v-list
                    v-list-item
                      v-list-item-title.red--text Пожаловаться
          v-row(
              no-gutters 
              align="center"
              class="spacer")
            v-col(cols="10")
              strong.ml-2 {{ item.category_id | getCatalogValue("request_categories") }}
              p.ml-2.ft-14.ma-0 Индивидуальная заявка №{{ item.id }} 
                span.text-bold(:class="getColorType(item.request_type_id)") {{ item.request_type_id | getCatalogValue("request_types") }}
          v-row.ma-0.my-2
            v-col.pa-0.pl-2(cols="6")
              span(class="grey--text ft-12") Дата: 
              span.ft-12 {{ item.created_at | timestamp(true, { hideTime: true }) }}
            v-col.pa-0(cols="6")
              span(class="grey--text ft-12" v-if="item.employee_period_of_execution") Срок: 
              span.ft-12 {{ item.employee_period_of_execution | dateFormat(true) }}
          v-divider
        v-row(class="fill-height")
          v-col.d-flex.align-center(cols="6")
            span.grey--text.px-2 Заявка №{{item.id}}
          v-col.d-flex.justify-end(cols="6")
            v-card-actions.pa-0.px-3
              span(@click.prevent)
                Chat(:roomName="'Квартира №' + item.apartment.apartment_number" :key="index" :room_id="item._id")
              span.grey--text {{ item.comment_count }}
  v-fab-transition
    v-btn(
      v-show="!hidden"
      to="/requisition/add"
      bottom
      right
      fab
      dark
      fixed 
      color="primary"
      style="bottom:65px;"
    )
      v-icon mdi-plus
</template>

<script>
import { mapGetters } from "vuex";
import AvatarLogo from "@/assets/img/avatar.svg"
import Chat from '@/components/widgets/Chat.vue'
export default {
  props: ['items'],
  data() {
    return {
      hidden: false
    }
  },
  computed: {
		...mapGetters({
      catalog: "catalog/GET_CATALOG",
      Token: "Token",
    }),
  },
  methods: {
    avatar(item) {
      return (`${window.localStorage.env_api}/${item.user.avatar}`)
    },
    getColorType(el) {
      let type = this.catalog.request_types.find((e => e._id == el))
			switch(type.sys_name) {
				case 'new':
					return 'blue--text'
				case 'accepted':
					return 'warning--text'
				case 'done':
					return 'success--text'
				case 'in_work':
					return 'purple darken-2--text'
				case 'canceled':
					return 'red--text'
				case 'rejected':
					return 'red--text'
				case 'overdue':
					return 'red--text'
				case 'canceled_by_user':
					return 'red--text'
				case 'not_done':
					return 'black-bold'
			}
    },
  },
  components: {
    AvatarLogo,
    Chat
  }
}
</script>

<style scoped>
.req-card {
  box-shadow: -1px 4px 34px -5px rgba(0, 0, 0, 0.07) !important;
}
</style>