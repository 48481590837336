<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          icon
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-chat-outline</v-icon>
        </v-btn>
      </template>
      <div>
        <vue-advanced-chat
          v-if="chat"
          height="80vh"
          show-audio="false"
          show-add-room="false"
          :current-user-id="profile._id"
          :rooms="JSON.stringify(rooms)"
          :text-messages="JSON.stringify(textMessages)"
          :rooms-loaded="true"
          rooms-list-opened="false"
          single-room="true"
          :messages="JSON.stringify(messages)"
          :messages-loaded="messagesLoaded"
          show-reaction-emojis="false"
          message-actions="[]"
          :username-options="JSON.stringify(usernameOptions)"
          @send-message="sendMessage($event.detail[0])"
          @fetch-messages="fetchMessages($event.detail[0])"
        >
          <div slot="room-header">
            <v-btn color="primary" icon @click="sheet = false">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-3">{{roomName}}</span>
          </div>
        </vue-advanced-chat>
      </div>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { register } from 'vue-advanced-chat'
import { timestampToDdmmyyyy } from "@/helpers/helpers";
register()
export default {
  props: ['roomName', 'room_id'],
	data() {
		return {
      chat: false,
      page: 1,
      result: null,
      sheet: false,
      loaded: false,
			currentUserId: '1234',
      comments: [],
			rooms: [
				{
					roomId: '1',
					roomName: 'Загрузка...',
					// avatar: 'https://66.media.tumblr.com/avatar_c6a8eae4303e_512.pnj',
					// users: [
					// 	{ _id: '62e79f32c3189065897b4a07', username: 'Петрович' },
					// 	{ _id: '62da3ef6aa086a3ccd596817', username: 'Петрович' },
					// 	{ _id: '62da3ef6aa086a3ccd596814', username: 'Петрович' },
					// 	{ _id: '62da3ef6aa086a3ccd596815', username: 'Петрович' },
					// 	{ _id: '62da3ef6aa086a3ccd596816', username: 'Петрович' },
					// ]
				}
			],
      usernameOptions: {minUsers: 0, currentUser: true},
			messages: [],
			messagesLoaded: false,
      templatesText: [
        {
          tag: 'help',
          text: 'This is the help'
        },
        {
          tag: 'action',
          text: 'This is the action'
        }
      ],
      textMessages: {
        ROOMS_EMPTY: 'Нет разговора',
        ROOM_EMPTY: 'Цепочка не выбрана',
        NEW_MESSAGES: 'Новые сообщения',
        MESSAGE_DELETED: 'Это сообщение было удалено',
        MESSAGES_EMPTY: 'Нет сообщений',
        CONVERSATION_STARTED: 'Начало чата:',
        TYPE_MESSAGE: 'Введите ваше сообщение',
        SEARCH: 'Искать',
        IS_ONLINE: 'В сети',
        LAST_SEEN: 'последнее соединение ',
        IS_TYPING: 'печатает...',
        CANCEL_SELECT_MESSAGE: 'Отменить выбор'
      },
		}
	},
  watch: {
    'sheet'() {
      setTimeout(() => { this.chat = this.sheet }, 200);
      this.page = 1
      this.messages = []
      this.items = []
    }
  },
    computed: {
    ...mapGetters({
      profile: "getStorageProfile",
    }),
  },
	methods: {
    timestampToDdmmyyyy,
		async fetchMessages({ options = {} }) {
      console.log('options', options)
      if(this.room_id) {
        const res = await this.$api.requisitions.load_comments(this.room_id, this.page);
        this.comments = res.data.reverse()
        this.result = res
        this.page = ++this.page
      }
      if(this.comments.length) {
        console.log('not empty')
        
        setTimeout(() => {
          if (options.reset) {
            this.messages = []
            this.messages = this.addMessages(true)
          } else {
            this.messages = [...this.addMessages(), ...this.messages]
            console.log('this.result', this.result)
            if(this.page > this.result.pages) this.messagesLoaded = true
          }
          // this.addNewMessage()
        })
      } else {
        this.messagesLoaded = false
        setTimeout(() => {
            this.messagesLoaded = true
            console.log('empty')
        }, 200);
      }
      
		},
		addMessages(reset) {
			const messages = []
      
      if(this.room_id) {
        
        if(this.comments.length) {
          
          for(let i = 0; i < this.comments.length; i++) {
            messages.push({
              _id: reset ? i : this.messages.length + i,
              content: this.comments[i].comment,
              senderId: this.comments[i].user._id,
              username: this.comments[i].user.name,
              date: timestampToDdmmyyyy(this.comments[i].created_at, true, true),
              timestamp: this.$options.filters.timestamp(this.comments[i].created_at, false, {clock: true}),
              avatar: this.comments[i].user.avatar ? window.localStorage.env_api + '/' + this.comments[i].user.avatar : require('@/assets/img/avatar.svg'),
            })
          }
        }
      }
      console.log('message2222', messages)
			return messages
		},

		async sendMessage(message) {
      try {
        let data = {
          comment: message.content,
          apartment_id: this.profile.main_apartment._id,
          record_id: this.room_id
        }
        await this.$api.requisitions.set_comment(data);
      } catch (error) {
        console.log('err comment send', error)
      }
			this.messages = [
				...this.messages,
				{
					_id: this.messages.length,
					content: message.content,
					senderId: this.profile._id,
					timestamp: new Date().toString().substring(16, 21),
					date: new Date().toLocaleDateString('ru-RU')
				}
			]
		},

		addNewMessage() {
			setTimeout(() => {
				this.messages = [
					...this.messages,
					{
						_id: this.messages.length,
						content: 'NEW MESSAGE',
						senderId: '1234',
						timestamp: new Date().toString().substring(16, 21),
						date: new Date().toDateString()
					}
				]
			}, 2000)
		},
	},
}
</script>