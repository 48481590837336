<template lang="pug">
div.login-view
  div(v-if="view == 'login'")
    div.text-center
      mainLogo
    div(v-if="!codeSent" class="mx-5")
      v-text-field(
        v-model="phone"
        placeholder="+7 (***)***_**_***"
        v-mask="'+7-###-###-##-##'"
        class="border-all mt-10"
        solo
        dense
        flat
        hide-details
      )
      v-btn(block color="primary" class="text-capitalize font-weight-regular mt-10 py-5" :disabled="phone.length < 16" @click="sendCode") Отправить СМС
      v-btn(block outlined color="primary" class="text-none font-weight-regular mt-3 py-5" :disabled="phone.length < 16" @click="toTelegram" target="_blank") Войти через Telegram
      p.text-caption.text-center.mt-5.grey--text Продолжая вы принимаете 
        a(@click="$router.push('terms')") Пользовательское соглашение 
        span и 
        a(@click="$router.push('policy')") Политику конфиденциальности
    div(v-else)
      div.mx-5.text-center.my-5
        h1.font-weight-light СМС
        p.mt-5 Код отправлен на номер {{ phone }}
        v-otp-input.otp(v-model="code" plain length="4" type="number" @finish="autorization")
        div.mt-5.grey--text
          div(v-if="!sendCodeAgain")
            span Повторите попытку (
            countdown(ref="countdown" :time="59000" :auto-start="true" @end="finishCountdown" v-slot="{ seconds }") {{ seconds }}) 
            span или войдите через 
            a.text-decoration-none.blue--text(href="https://t.me/Aulakz_bot") Telegram
          div(v-else)
            a.blue--text(@click="sendCodeAgain = false") Повторите попытку 
            span или войдите через 
            a.text-decoration-none.blue--text(href="https://t.me/Aulakz_bot" target="_blank") Telegram
</template>

<script>
import { mapGetters } from "vuex";
import mainLogo from "@/assets/img/login-logo.svg"
import Countdown from '@chenfengyuan/vue-countdown';
export default {
  data() {
    return {
      view: 'login',
      phone: '',
      code: null,
      policy: false,
      codeSent: false,
      sendCodeAgain: false,
    }
  },
  computed: {
    ...mapGetters(["Token", "Sms", "getProfile"])
  },
  methods: {
    async autorization() {
      const phone = this.phone.replace(/[{()}]/g, "-");
      const data = {
        phone,
        password: this.code,
      };
      
      try {
        console.log('datas', data)
        await this.$store.dispatch('auth', data)
        await this.$store.dispatch('fetchProfile')
        console.log('getProfile', this.getProfile)
        if(this.getProfile.main_apartment) {
          window.location.assign(`/#/`);
        } else {
          window.location.assign(`/#/house/add`);
        }
        
      } catch(error) {
        this.$root.snackbar.show({ color: "red", message: error.message || 'Произошла ошибка, повторите попытку!' });
        console.log('err', error)
      }
    },
    async registration() {
      const phone = this.phone.replace(/[{()}]/g, "-");
      const data = {
        phone,
      };
      try {
        await this.$store.dispatch('reg', data)
      } catch(error) {
        this.$root.snackbar.show({ color: "red", message: error.message || 'Произошла ошибка, повторите попытку!' });
        console.log('err', error)
      }
    },
    toTelegram() {
      this.codeSent = true
      window.location.href = 'https://t.me/Aulakz_bot?start'
    },
    async sendCode() {
      console.log('this.Sms', typeof this.Sms)
      if(this.Sms == 'true') {
        console.log('sms 2', this.Sms)
        try {
          let res = await this.$api.user.generate_password({
            phone: this.phone,
          });
          console.log('res generate', res)
        } catch(error) {
          console.log('err', error)
          if(error.error.response.status == 422) {
            this.registration()
          }
        }
      }
      this.codeSent = true
    },
    finishCountdown() {
      this.sendCodeAgain = true
    }
  },
  created() {
    if(window.localStorage?.token) {
      this.$router.push('/')
    }
    if(window.localStorage?.sms) {
      this.$store.dispatch('smsSetting', window.localStorage.sms)
    }
    console.log('sms', this.Sms)
  },
  components: {
    Countdown,
    mainLogo
  }
}
</script>

<style scoped>
.login-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>