export default function(instance) {
	return {
		// * Список домов пользователя
		load_houses() {
			return instance({
				url: `/v1/user/apartment/`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},
		// * Список прикрепл. пользователей к квартире
		load_house_users(house_id) {
			return instance({
				url: `/v1/user/apartment/owner/getAll/${house_id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},
		// * Список домов по городу
		load_houses_by_city(city_id) {
			return instance({
				url: `/houses?city_id=${city_id}&short=true`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},
		// * Добавление квартиры
		add_apartment(params) {
			return instance({
				url: `/v2/users/ownership/create/apartment`,
				method: "post",
        params
			})
				.then(res => res.data)
				.catch(error => error);
		},
		// * Добавление парковочного места
		add_parking(params) {
			return instance({
				url: `/v2/users/ownership/create/parking-space`,
				method: "post",
        params
			})
				.then(res => res.data)
				.catch(error => error);
		},
		// * Добавление нежилого помещения
		add_non_residential(params) {
			return instance({
				url: `/v2/users/ownership/create/non-residential-premise`,
				method: "post",
        params
			})
				.then(res => res.data)
				.catch(error => error);
		},
		// * Добавление нежилого помещения
		delete_apartment(id) {
			return instance({
				url: `/v1/user/apartment/${id}`,
				method: "delete",
			})
				.then(res => res.data)
				.catch(error => error);
		},
  }
}