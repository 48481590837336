<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon to="/votes" plain)
      BackLogo
    v-app-bar-title.text-center(color="white") Голосование
  Spinner(v-if="!item")
  div(v-else)
    v-container
      p.text-semibold {{ item.vote.house.apartment_address }}
      div
        p.ft-14.grey--text.float-left(v-show="item.vote.apartment ") № квартиры: {{ item.vote.apartment }}
        p.ft-14.grey--text.float-right № голосования: {{ item.vote.id }}
    v-divider.mt-3
    v-container
      div
        p.ft-14.grey--text.float-left Создан: {{ item.vote.created_at | timestamp }}
        p.ft-14.grey--text.float-right Окончание: {{ item.vote.time_vote | timestamp }}
      div.mt-10
        template(v-for="(ques, i) in item.vote.questions")
          v-card.pa-2(:key="i" elevation="7")
            p.ma-0 Вопрос {{ i+1 }} из {{ item.vote.questions.length }}
            v-card-title.pa-0 {{ ques.title }}
            v-card-subtitle.pt-3.pa-0 {{ ques.description }}
            div(v-if="!item.vote.voted")
              v-row
                v-col
                  v-radio-group(v-model="radioGroup")
                    v-radio(label="За" value="yes")
                    v-radio(label="Против" value="no")
                    v-radio(label="Воздержусь" value="refrain")
            div.mt-10(v-else)
              v-row.align-center
                v-col.pa-0.px-4(cols="4") 
                  span.ft-14.text-semibold(v-show="ques.analytic.yes.selectedAuth") Вы: 
                  span.ft-14.grey--text За
                v-col.pa-0.px-4(cols="5") 
                  v-progress-linear(:value="ques.analytic.yes.percent" color="primary")
                v-col.pa-0.px-4(cols="3") 
                  span.ft-14 {{ques.analytic.yes.percent}}%
              v-row.align-center
                v-col.pa-0.px-4(cols="4") 
                  span.ft-14.text-semibold(v-show="ques.analytic.no.selectedAuth") Вы: 
                  span.ft-14.grey--text Против
                v-col.pa-0.px-4(cols="5") 
                  v-progress-linear(:value="ques.analytic.no.percent" color="primary")
                v-col.pa-0.px-4(cols="3") 
                  span.ft-14 {{ques.analytic.no.percent}}%
              v-row.align-center.pb-4
                v-col.pa-0.px-4(cols="4") 
                  span.ft-14.text-semibold(v-show="ques.analytic.refrain.selectedAuth") Вы: 
                  span.ft-14.grey--text Воздержусь
                v-col.pa-0.px-4(cols="5") 
                  v-progress-linear(:value="ques.analytic.refrain.percent" color="primary")
                v-col.pa-0.px-4(cols="3") 
                  span.ft-14 {{ques.analytic.refrain.percent}}%
          v-btn.mt-5(block v-show="!item.vote.voted" color="primary" :disabled="!radioGroup") Проголосовать
    //- pre {{ item }}
</template>

<script>
import BackLogo from "@/assets/img/back-button.svg"
export default {
  data() {
    return {
      item: null,
      radioGroup: null,
    }
  },
  methods: {
    
  },
  async mounted() {
    this.item = await this.$api.votes.get_vote(this.$route.params.id);
  },
  components: {
    BackLogo
  }
}
</script>