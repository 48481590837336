import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/router';
// import VueCordova from 'vue-cordova'
var VueCordova = require('vue-cordova');
import "./assets/css/style.css";
import VueLoadmore from 'vuejs-loadmore';
Vue.use(VueLoadmore);
// Plugins
import api from "./plugins/api";
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify'

// Global mixin
import "./mixins/globalMixin";

// Helpers
import "@/helpers/filters";
import "@/helpers/defineProperties";

// Directives
import { VueMaskDirective } from "v-mask";

// Global components
import Spinner from "@/components/app/Spinner";

Vue.component("Spinner", Spinner);

Vue.directive("mask", VueMaskDirective);

Vue.use(VueCordova, {
  optionTestKey: 'optionTestValue'
})

if (window.location.protocol === 'file:' || window.location.port === '3000') {
  var cordovaScript = document.createElement('script')
  cordovaScript.setAttribute('type', 'text/javascript')
  cordovaScript.setAttribute('src', 'cordova.js')
  document.body.appendChild(cordovaScript)
}

Vue.use(api);
Vue.config.productionTip = false

let deviceReady = false
let vueMounted = false
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  data: function () {
    return {
      cordova: Vue.cordova
    }
  },
  mounted: function(){
    vueMounted = true
    if (deviceReady) this.everythingReady()
  },
  methods: {
    everythingReady: function() {
        alert('Vue is mounted and everything is ready')
    }
  }
}).$mount('#app')

document.addEventListener('deviceReady', () => {
  deviceReady = true
  if (vueMounted) this.everythingReady()
}, false)

Vue.cordova.on('deviceready', () => {
  console.log('Vue Cordova : device is ready !');
});