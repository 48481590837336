import Vue from "vue";

import {
	getFullname,
	timestampToDdmmyyyy,
	getCatalogValue,
} from "@/helpers/helpers";

// * Лимитирование текста описания в таблицах
Vue.filter("textLimit", function(value, limit = 40) {
	const LIMIT = limit;

	if (!value?.length) return "---";
	if (value.length > LIMIT) return value.substr(0, LIMIT) + "...";

	return value;
});

// * Форматирование timestamp в dd/mm/yyyy
Vue.filter("timestampToDdmmyyyy", timestampToDdmmyyyy);

// * Форматирование YodaTime даты для DatePicker и в сокращенную дату
Vue.filter("dateFormat", function(value, short = false) {
	if (!value) return "";

	const months = [
		"Января",
		"Февраля",
		"Марта",
		"Апреля",
		"Мая",
		"Июня",
		"Июля",
		"Августа",
		"Сентября",
		"Октября",
		"Ноября",
		"Декабря",
	];
	const year = value.substr(0, 4);
	let month = months[+value.substr(5, 2) - 1];
	const day = +value.substr(8, 2);

	if (short) month = month.substr(0, 3);

	return `${day} ${month} ${year}`;
});

/**
 * * Конвертирование Timestamp
 * @params addYear = true, чтобы добавить год
 */
Vue.filter("timestamp", function(
	UNIX_timestamp,
	addYear = false,
	options = { utc: false, hideTime: false, clock: false },
	milliseconds = false,
) {
	if (!UNIX_timestamp) {
		return "---";
	}
	const a = !milliseconds ? new Date(UNIX_timestamp * 1000) : new Date(UNIX_timestamp);
	
	const months = [
		"Янв",
		"Фев",
		"Мар",
		"Апр",
		"Май",
		"Июн",
		"Июл",
		"Авг",
		"Сен",
		"Окт",
		"Ноя",
		"Дек",
	];

	if (options.hideTime) {
		const year = a.getUTCFullYear();
		const month = months[a.getUTCMonth()];
		const date = (Number(a.getUTCDate()) < 10 ? "0" : "") + a.getUTCDate();
		const time =
			date + " " + month + " " + (addYear ? year : "");
		return time;
	}
	if (options.utc) {
		const year = a.getUTCFullYear();
		const month = months[a.getUTCMonth()];
		const date = (Number(a.getUTCDate()) < 10 ? "0" : "") + a.getUTCDate();
		const hour = (Number(a.getUTCHours()) < 10 ? "0" : "") + a.getUTCHours();
		const min = (Number(a.getUTCMinutes()) < 10 ? "0" : "") + a.getUTCMinutes();
		const time =
			date + " " + month + " " + (addYear ? year + "," : "") + hour + ":" + min;
		return time;
	}
	if (options.clock && !options.utc) {
		const hour = (Number(a.getHours()) < 10 ? "0" : "") + a.getHours();
		const min = (Number(a.getMinutes()) < 10 ? "0" : "") + a.getMinutes();
		const time =
			hour + ":" + min;
		return time;
	}

	const year = a.getFullYear();
	const month = months[a.getMonth()];
	const date = (Number(a.getDate()) < 10 ? "0" : "") + a.getDate();
	const hour = (Number(a.getHours()) < 10 ? "0" : "") + a.getHours();
	const min = (Number(a.getMinutes()) < 10 ? "0" : "") + a.getMinutes();
	const time =
		date + " " + month + " " + (addYear ? year + "," : "") + hour + ":" + min;
	return time;
});

// * Полное имя пользователя
/**
 * @params obj - объект пользователя, получаемый из сервера
 */
Vue.filter("fullname", getFullname);

// * Возвращает значение определнного поля из каталога по _id и
Vue.filter("getCatalogValue", getCatalogValue);
