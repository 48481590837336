<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon to="/notifications" plain)
      BackLogo
    v-app-bar-title.text-center(color="white") Уведомления
  v-container.pa-5(v-if="items.data.length")
    template(v-for="(item, i) in items.data")
      v-card.mb-3(color="#F0F0F0" elevation="0")
        v-card-subtitle {{ item.created_at | timestamp }}
        v-card-title.pa-0.px-4 {{ item.title }}
        v-card-text {{ item.message }}
  Spinner(v-else)
</template>

<script>
import BackLogo from "@/assets/img/back-button.svg"
export default {
  data() {
    return {
      items: {
        data: []
      }
    }
  },
  async beforeMount() {
		this.items = await this.$api.notifications.load_data_by_ksk(this.$route.params.id);
	},
  components: {
    BackLogo
  }
}
</script>