<template>
  <v-app class="desktop">
    <v-main>
      <router-view></router-view>
      <Notification ref="notification" :data="notify" />
      <Snackbar ref="snackbar" />
    </v-main>
    <BottomNavigation v-if="Token && getStorageProfile.main_apartment" />
  </v-app>
</template>

<script>/* eslint-disable */
import { mapGetters } from "vuex";
import Snackbar from "@/components/app/Snackbar";
import Notification from "@/components/app/Notification";
import BottomNavigation from './components/layouts/BottomNavigation.vue'
export default {
  name: 'App',

  components: {
    BottomNavigation,
    Snackbar,
    Notification
  },

  data: () => ({
    notify: null,
  }),
  computed: {
    ...mapGetters(["Token", "getStorageProfile"])
  },
  created() {
    if(window.localStorage.token) {
      this.$store.dispatch('getToken', window.localStorage.token)
    }
  },
  mounted() {
    this.$root.snackbar = this.$refs.snackbar;
    if(!this.Token && this.$route.name !== 'login') {
      this.$router.push('/login')
    }
    this.$store.dispatch("catalog/loadCatalog");
    this.$store.dispatch("getVersionApi");
    document.addEventListener("deviceready", this.checkPermissions, false)
    // on('deviceready', () => {
    //   console.log('Cordova is ready!')
    // })
  },
  methods: {
    isCordova() {
      if (window.hasOwnProperty("cordova")) {
        return true
      }
      return false;
    },
    onBackButton () {
      this.$router.go(-1)
    },
    checkPermissions() {
      if (device.platform === 'Android') {
        console.log('It is android platform', JSON.stringify(cordova.plugins.firebase))
        cordova.plugins.firebase.messaging.onBackgroundMessage(function(payload) {
          console.log("New background FCM message: ", JSON.stringify(payload));
          if(payload.type_id == 3) {
            window.location.assign('/#/requisitions/' + payload.request_id);
          }
        });
        cordova.plugins.firebase.messaging.onMessage((payload) => {
          console.log("New foreground FCM message: ", JSON.stringify(payload));
          this.notify = payload
        });
        // cordova.plugins.firebase.messaging.getToken((token) => {
        //   console.log('notification TOKEN', token);
        //   this.setFirebaseTokenAndroid(token)
        // })
        cordova.plugins.firebase.messaging.getToken().then((token) => {
          console.log("Got device token: ", token);
          this.setFirebaseTokenAndroid(token)
        });
        document.addEventListener('backbutton', this.onBackButton, false)
      }
      if (this.isCordova()) {
        console.log("<QRCOM> Cordova: Check permissions");
        var permissions = cordova.plugins.permissions;
        var list = [
          permissions.CAMERA,
          permissions.RECORD_AUDIO,
          permissions.ACCESS_FINE_LOCATION,
        ];
        // TODO: hasPermission alternative
        permissions.checkPermission(
          list,
          (status) => {
            if (!status.hasPermission) {
              permissions.requestPermissions(
                list,
                (status) => {
                  if (!status.hasPermission) console.log("<QRCOM> Cordova: Permission error");
                },
                () => console.log("<QRCOM> Cordova: Permission error")
              );
            }
          },
          null
        );
      } else console.log("<QRCOM> App: Running in browser, can't check permissions");
    },
    async setFirebaseTokenAndroid(token) {
      const res = await this.$api.user.set_android_token(token)
      console.log('result set ANDROID TOKEN', JSON.stringify(res))
    }
  }
};

</script>

<style>
  @media (min-width: 920px) {
    .desktop {
    max-width: 700px;
    margin-left: 30%;
    margin-right: 30%;
  }
}
</style>
