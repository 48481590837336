<template>
	<v-snackbar
		v-model="showSnackbar"
		:color="color"
		:timeout="timer"
		bottom
		text
	>
		<v-icon v-if="icon" left>{{ icon }}</v-icon>
		<span>{{ message }}</span>
		<template v-slot:action="{ attrs }">
			<v-btn color="black" icon v-bind="attrs" @click="showSnackbar = null">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	name: "Snackbar",
	data() {
		return {
			showSnackbar: false,
			message: "",
			color: "success",
			icon: "mdi-check",
			timer: 3000,
		};
	},
	methods: {
		show(data) {
			if (this.showSnackbar) {
				this.showSnackbar = false;
				setTimeout(() => this.setData(data), 300);
			} else {
				this.setData(data);
			}
		},

		setData(data) {
			this.showSnackbar = true;
			this.message = data.message || 'missing "message".';
			this.color = data.color || "success";
			this.timer = data.timer || 3000;
			this.icon = data.icon || null;
		},
	},
};
</script>

<style>
.v-snack__wrapper {
	margin-bottom: 60px;
}
</style>