import Vue from 'vue';
import Router from 'vue-router';

import MainPage from '../pages/main.vue';

import QrView from '../pages/QrView.vue'
import Menu from '../pages/menu/menu.vue'
import Login from '../pages/LoginView.vue'
import Terms from '../pages/other/terms.vue'
import Policy from '../pages/other/policy.vue'
import Settings from '../pages/menu/settings.vue'
import ServicesView from '../pages/servicesView.vue'
import ProfilePage from '../pages/menu/profileView.vue'
import DeleteAccount from '../pages/menu/deleteAccount.vue'
import RequisitionAdd from '../pages/requisitions/requisitionAdd.vue'
import RequisitionsView from '../pages/requisitions/requisitionsView.vue'
import requisitionsDetail from '../pages/requisitions/requisitionsDetail.vue'
import notificationsView from '../pages/notificationsView.vue'
import notificationsDetail from '../pages/notificationsDetail.vue'
import houseAdd from '../pages/houses/houseAdd.vue'
import housesView from '../pages/houses/housesView.vue'
import houseUsers from '../pages/houses/houseUsers.vue'
import votesView from '../pages/votesView.vue'
import votesDetail from '../pages/votesDetail.vue'




Vue.use(Router);

class RouteMeta {
  title;

  constructor ({ title }) {
    this.title = title;
  }
} 

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'main-view',
      component: MainPage,
      meta: new RouteMeta({ title: 'Main' })
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: new RouteMeta({ title: 'Login' })
    },
    {
      path: '/policy',
      name: 'policy',
      component: Policy,
      meta: new RouteMeta({ title: 'Policy' })
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms,
      meta: new RouteMeta({ title: 'Terms' })
    },
    {
      path: '/menu',
      name: 'menu-view',
      component: Menu,
      meta: new RouteMeta({ title: 'Menu' })
    },
    {
      path: '/profile',
      name: 'profile-view',
      component: ProfilePage,
      meta: new RouteMeta({ title: 'Profile' })
    },
    {
      path: '/menu/settings',
      name: 'settings-view',
      component: Settings,
      meta: new RouteMeta({ title: 'Settings' })
    },
    {
      path: '/menu/settings/delete',
      name: 'delete-view',
      component: DeleteAccount,
      meta: new RouteMeta({ title: 'Delete' })
    },
    {
      path: '/services',
      name: 'services-view',
      component: ServicesView,
      meta: new RouteMeta({ title: 'Services' })
    },
    {
      path: '/qr',
      name: 'qr-view',
      component: QrView,
      meta: new RouteMeta({ title: 'QR' })
    },
    {
      path: '/requisitions',
      name: 'requisitions-view',
      component: RequisitionsView,
      meta: new RouteMeta({ title: 'Requisitions' })
    },
    {
      path: '/requisitions/:id',
      name: 'requisition-detail',
      component: requisitionsDetail,
      meta: new RouteMeta({ title: 'Request' })
    },
    {
      path: '/requisition/add',
      name: 'requisition-add',
      component: RequisitionAdd,
      meta: new RouteMeta({ title: 'Requisitions Add' })
    },
    {
      path: '/notifications',
      name: 'notifications-view',
      component: notificationsView,
      meta: new RouteMeta({ title: 'Notifications' })
    },
    {
      path: '/notifications/:id',
      name: 'notifications-detail',
      component: notificationsDetail,
      meta: new RouteMeta({ title: 'Notifications Detail' })
    },
    {
      path: '/houses',
      name: 'houses-view',
      component: housesView,
      meta: new RouteMeta({ title: 'Houses' })
    },
    {
      path: '/houses/:id',
      name: 'house-users',
      component: houseUsers,
      meta: new RouteMeta({ title: 'House Users' })
    },
    {
      path: '/house/add',
      name: 'house-add',
      component: houseAdd,
      meta: new RouteMeta({ title: 'House Add' })
    },
    {
      path: '/votes',
      name: 'votes',
      component: votesView,
      meta: new RouteMeta({ title: 'Votes' })
    },
    {
      path: '/votes/:id',
      name: 'votes-detail',
      component: votesDetail,
      meta: new RouteMeta({ title: 'Votes Detail' })
    },
  ]
});

// This callback runs before every route change, including on initial load
// router.beforeEach((to, from, next) => {
//   const routeMeta = to.meta;
//   store.dispatch('topToolbar/changeTitle', routeMeta.title);
//   next();
// });

export default router;