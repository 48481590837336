<template>
  <div v-if="images.length">
    <!-- directive -->
    <viewer :images="images">
      <v-row>
        <v-col v-for="(src, index) in images" cols="6" :key="src">
          <v-img height="100" width="160" :src="src" class="rounded-lg" @click="show(index)" />
        </v-col>
      </v-row>
    </viewer>
    <!-- component -->
    <!-- <viewer :images="images">
      <v-img v-for="src in images" :key="src" :src="src" max-height="100" max-width="160" class="rounded-lg" />
    </viewer> -->
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
Vue.use(VueViewer)
export default {
  props: {
    images: {
			type: Array,
    }
  },
  methods: {
    show(index) {
      this.$viewerApi({
        options: {
          initialViewIndex: index,
          toolbar: false,
          tooltip: false,
          zoomable: true,
          rotatable: true,
          scalable: true,
          fullscreen: true,
          inline: true,
          movable: false,
        },
        images: this.images,
        
      })
    },
  },
}
</script>