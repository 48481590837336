var api = {
  getToken(){
    window.localStorage.getItem('token');
  },
  saveToken(name, e){
    window.localStorage.setItem(name, e);
  },
  deleteToken(name){
    window.localStorage.removeItem(name);
  },
  getData(name){
    window.localStorage.getItem(name);
  },
  setData(name, e){
    window.localStorage.setItem(name, e);
  },
  deleteData(name){
    window.localStorage.removeItem(name);
  },
}

export {api}