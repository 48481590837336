<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon @click="$router.go(-1)")
      BackLogo
    v-app-bar-title.text-center(color="white") Уведомления
  div.absolute-center.text-center(v-if="!items.length && !loaded.length") 
    XLogo
    p.ft-22.text-semibold Нет уведомлений
  v-list(two-line v-else-if="items.length")
    template(v-for="(item, i) in items")
      v-list-item(:key="i" @click="delayAnimationGo('/notifications/' + item._id)")
        v-list-item-content
          v-list-item-title.font-weight-medium {{ item.name }}
          v-list-item-subtitle.mt-1(v-if="item.last_notification") {{ item.last_notification.message }}
        v-list-item-action.ma-0
          v-list-item-action-text
            p.ma-0.mt-1(v-if="item.last_notification") {{ item.last_notification.created_at | timestamp }}
            p.ma-0.primary--text.d-flex.justify-end {{ item.unread_count }} новых
      v-divider
  Spinner(v-else)
</template>

<script>
import BackLogo from "@/assets/img/back-button.svg"
import XLogo from "@/assets/img/x-icon.svg"
export default {
  data() {
    return {
      items: [],
      loaded: 'wait'
    }
  },
  async beforeMount() {
		this.loaded = await this.$api.notifications.load_data();
		this.items = this.loaded
	},
  components: {
    XLogo,
    BackLogo
  }
}
</script>