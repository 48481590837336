import axios from "axios";
const state = {
	catalog: null,
	kskList: [],
};

const getters = {
	GET_CATALOG: state => state.catalog || false,
	GET_KSK_LIST: state => state.kskList,
};

const mutations = {
	SET_CATALOG(state, data) {
		state.catalog = data;
	},
	SET_KSK_LIST(state, data) {
		state.kskList = data;
	},
};

const actions = {
	async loadCatalog(context) {
    try {
      const response = (await axios.get(`${window.localStorage.env_api}/v2/catalog`, {
        headers: {
          'Content-Type': 'application/json',
        }
      }));
      context.commit("SET_CATALOG", response.data);
    } catch(error) {
      console.log('err', error)
    }
	},

	// async loadKskList({ commit }) {
	// 	try {
	// 		const res = await $api.dropdowns.load_ksk_list();
	// 		commit("SET_KSK_LIST", res);
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// },
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
