<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-app-bar-title.text-center(color="white") Главная
  Slider(title="Уведомления" :data="slides")
  v-list(two-line)
    template(v-for="(item, i) in items")
      v-list-item.py-2(:key="i" @click="delayAnimationGo(item.route)")
        v-list-item-icon.ma-0.mr-3.mt-2
          component(:is="item.icon")
        v-list-item-content.pa-0
          v-list-item-title.font-weight-medium.text-semibold {{ item.name }}
          v-list-item-subtitle.mt-1 {{ item.description }}
      v-divider.mx-3
</template>

<script>
import { mapGetters } from "vuex";
import Slider from '../components/mainSlider.vue'
import menuList from "@/assets/img/menu-list.svg"
import menuBell from "@/assets/img/menu-bell.svg"
import menuPeople from "@/assets/img/menu-people.svg"
import menuDatabase from "@/assets/img/menu-database.svg"
import menuVote from "@/assets/img/menu-vote.svg"
export default {
  name: 'MainPage',
  data() {
    return {
      swiper: 0,
      sheet: false,
      items: [
        {
          id: 1, name: 'Заявки', description: 'Ваши заявки по сервису', icon: menuList, route: '/requisitions'
        },
        {
          id: 2, name: 'Уведомления', description: 'Важные оповещения', icon: menuBell, route: '/notifications'
        },
        {
          id: 3, name: 'Соседи', description: 'Чат соседей', icon: menuPeople, route: '/requisitions'
        },
        {
          id: 4, name: 'Показания счетчиков', description: 'Запись показаний счетчиков', icon: menuDatabase, route: '/requisitions'
        },
        {
          id: 5, name: 'Опросы', description: 'Голосование по вопросам', icon: menuVote, route: '/votes'
        },
      ],
      slides: [
        {
        name: 'Аварийное отключение', 
        text: 'В связи с порывом трубы водоснабжения, вода будет отключена до 15:00',
        address: 'пр. Туран 5/3',
        created_at: '12:36, сегодня'
        },
        {
        name: 'Аварийное отключение', 
        text: 'В связи с порывом трубы водоснабжения, вода будет отключена до 15:00',
        address: 'пр. Туран 5/3',
        created_at: '12:36, сегодня'
        },
      ]
    }
  },
  computed: {
    ...mapGetters(["Token"])
  },
  created() {
    if(!this.Token && this.$route.name !== 'login') {
      this.$router.push('/login')
    }
  },
  components: {
    Slider,
  }
}
</script>
