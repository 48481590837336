export default function(instance) {
	return {
		// * Список уведомлений по УК
		load_data() {
			return instance({
				url: `/v2/user/notifications/hasKskNotification`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},
		load_data_by_ksk(ksk_id) {
			return instance({
				url: `/v2/user/notifications/?ksk_id=${ksk_id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},
  }
}