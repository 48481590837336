import Vue from 'vue'
import Vuex from "vuex";
// import Profile from './profile'
import Requisitions from './requisitions'
import Cordova from './cordova'
import User from './user'
import catalog from './catalog'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    
  },
  mutations: {
   
  },
  actions: {
   
  },
  getters: {
   
  },
  modules: {
    Requisitions,
    Cordova,
    User,
    catalog
  }
})