<template lang="pug">
div
  v-app-bar.center-bar(elevation="0" color="primary" dark app)
    v-app-bar-title Меню
  v-card.py-3(outlined @click="delayAnimationGo('/profile')")
    v-row(
      align="center"
      class="spacer ma-2"
      no-gutters
    )
      v-col.text-center(
        cols="2"
        sm="2"
        md="1"
      )
        v-avatar(
          size="36px"
        )
          img(  
            v-if="profile.avatar"
            alt="Avatar"
            :src="avatar"
          )
          AvatarLogo(v-else)
      v-col(
        sm="5"
        md="3"
        cols="8"
      )
        p.ft-18.ma-0.black--text.text-light {{ getFullname(profile) }}
        p.font-weight-light.ma-0 {{ profile.phone }}
      v-col.text-center(cols="2")
        v-btn(icon color="primary" small)
          v-icon mdi-chevron-right
  v-divider(style="border-width: initial;")
  v-container
    v-row
      v-col.d-flex.justify-center(cols="4")
        v-btn.btn-menu.py-7(text to="/notifications")
          BellLogo.logo-menu
          div.text-none.black--text Уведомления
      v-col.d-flex.justify-center(cols="4")
        v-btn.btn-menu.py-7(text to="/houses")
          HouseLogo
          div.text-none.black--text О доме
      v-col.d-flex.justify-center(cols="4")
        v-btn.btn-menu.py-7(text to="/votes")
          VoteLogo.logo-menu
          div.text-none.black--text Голосования
  v-divider(style="border-width: initial;")
  v-card(elevation="0" @click="delayAnimationGo('/menu/settings')")
    v-list
      v-list-item
        v-list-item-icon
          SettingsLogo
        v-list-item-content
          v-list-item-title Настройки
        v-list-item-icon
          v-icon mdi-chevron-right
</template>

<script>
import { mapGetters } from "vuex";
import { getFullname } from "@/helpers/helpers"
import BellLogo from "@/assets/img/bell.svg"
import HouseLogo from "@/assets/img/house.svg"
import VoteLogo from "@/assets/img/vote.svg"
import AvatarLogo from "@/assets/img/avatar.svg"
import SettingsLogo from "@/assets/img/settings.svg"
export default {
  computed: {
    ...mapGetters({
      profile: "getStorageProfile",
    }),
    avatar() {
      return (`${window.localStorage.env_api}/${this.profile.avatar}`)
    },
  },
  methods: {
    getFullname,
  },
  components: {
    VoteLogo,
    BellLogo,
    HouseLogo,
    AvatarLogo,
    SettingsLogo,
  }
}
</script>

<style>
.btn-menu .v-btn__content {
  display:flex;
  flex-direction:column;
}

.logo-menu {
  width: 24px;
  height: 24px;
}
</style>