import $api from "@/api/index";
import { api } from "@/helpers/mobile";
export default {
  state: {
    token: null,
    sms: true,
    profile: null,
    version: null,
  },
  mutations: {
    setToken(state, newToken) {
      return state.token = newToken
    },
    deleteToken(state) {
      return state.token = null
    },
    setSms(state, newSms) {
      return state.sms = newSms
    },
    setVersionApi(state, newVersion) {
      return state.version = newVersion
    },
    setProfile(state, newProfile) {
      return state.profile = newProfile
    },
  },
  actions: {
    async getVersionApi(context) {
      try {
        const res = await $api.user.get_minimal_version();
        context.commit("setVersionApi", res.data.version);
      } catch (error) {
        console.log('ERROR get Version', error)
        throw error;
      }
    },
    async auth(context, data) {
      try {
        const { phone, password } = data;
        const res = await $api.user.autorization(phone, password);
        api.saveToken('token', res.data.auth_token)
        context.commit("setToken", res.data.auth_token);
        return res.data.success;
      } catch (error) {
        console.log('Error get token auth', error)
        throw error;
      }
    },
    async reg(context, data) {
      try {
        const { phone } = data;
        console.log('data', data)
        console.log('phone', phone)
        const res = await $api.user.registration(phone);
        console.log('res reg', res)
        api.saveToken('token', res.data.auth_token)
        context.commit("setToken", res.data.auth_token);
        return res.data.success;
      } catch (error) {
        console.log('Error get token reg', error)
        throw error;
      }
    },
    async fetchProfile(context) {
      const res = await $api.user.load_data();
      console.log('JSON.stringify(res.data)', JSON.stringify(res.data))
      api.setData('profile', JSON.stringify(res.data))
      context.commit("setProfile", res.data);
    },
    logout(context) {
      context.commit("deleteToken");
      api.deleteToken('token')
      api.deleteData('profile')
    },
    getToken(context, data) {
      context.commit("setToken", data);
    },
    smsSetting(context, data) {
      context.commit("setSms", data);
    }
  },
  getters: {
    Token(state) {
      return state.token;
    },
    VersionApi(state) {
      return state.version;
    },
    Sms(state) {
      return state.sms;
    },
    getStorageProfile(state) {
      if(window.localStorage.profile) {
        return JSON.parse(window.localStorage.profile)
      } else {
        return state.profile;
      }
    },
    getProfile(state) {
      // if(window.localStorage.profile) {
      //   return JSON.parse(window.localStorage.profile)
      // } else {
        return state.profile;
      // }
    },
  }
}