<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon :to="prevRoute")
      BackLogo
    v-app-bar-title.text-center(color="white") QR Домофон
  div.scanner
    section.scanner__section
      qrcode-stream(@decode="onDecode" :camera="camera" :torch="flashlight" @init="onInit")
      div.scanner__mask.mask
        div.mask-top
        div.mask-middle
          Spinner(v-if="loading")
          div.mask-left
          div.scanner__target(:class="{ 'black': targetBlack }")
            span.border-1
            span.border-2
            span.border-3
          div.mask-right
        div.mask-bottom
          p.mb-5.text-light.flash-btn.white--text(v-if="torchSupport" @click="flashlight =!flashlight") Вспышка
            v-icon(:color="flashlight ? 'yellow' : 'grey lighten-1'") mdi-flash
  v-dialog(
    v-model="dialog"
    persistent
    max-width="290"
  )
    v-card.text-center
      v-card-title(class="text-h5" style="word-break: break-word") {{ errorText }}
      v-card-actions
      v-spacer
      div.pa-3
        v-btn.white--text(color="red darken-1" to="/#/" block @click="dialog = false") OК
</template>

<script>
import Vue from 'vue'
import { mapGetters } from "vuex";
import BackLogo from "@/assets/img/back-button.svg"
import { QrcodeStream } from 'vue-qrcode-reader'
export default {
  data() {
    return {
      camera: 'auto',
      qr: null,
      dialog: false,
      targetBlack: true,
      response: null,
      scanBleStatus: false,
      openSuccess: false,
      errorText: null,
      connected: false,
      device_id: null,
      torchSupport: false,
      scanner: false,
      flashlight: false,
      device: null,
      status: false,
      loading: true,
      cap: false,
      availableFirstError: true,
      prevRoute: null,
      BTModule: {
        SERVICE_UUID: "4fafc201-1fb5-459e-8fcc-c5c9c331914b",
        CHARACTERISTIC_UUID: "beb5483e-36e1-4688-b7f5-ea07361b26a8"
      }
    }
  },
  beforeRouteEnter(to, from, next) {
  next(vm => {
    vm.prevRoute = from
  })
},
  computed: {
    ...mapGetters(["Cordova"]),
  },
  methods: {
    async onInit (promise) {
      console.log('init qr reader', new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''))
      this.loading = true
      try {
        const { capabilities } = await promise
        this.cap = capabilities
        console.log('cap', capabilities)
        const TORCH_IS_SUPPORTED = !!capabilities.torch
        this.torchSupport = TORCH_IS_SUPPORTED
        console.log('TORCH_IS_SUPPORTED', TORCH_IS_SUPPORTED)
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          // user denied camera access permisson
        } else if (error.name === 'NotFoundError') {
          // no suitable camera device installed
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
        }
      } finally {
        this.loading = false
        this.targetBlack = false
      }
    },
    turnCameraOff () {
      this.camera = 'off'
    },
    onDecode (decodedString) {
      console.log(`SCANED DATA: ${decodedString}`);
      const params = decodedString.split("?").pop();
      this.qr = Object.fromEntries(new URLSearchParams(params));
      console.log('qr', this.qr);     
      this.loading = true
      this.turnCameraOff(),
      this.qrPass()
    },
    async qrPass() {
      let params = {
        datetime: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
        type: this.qr.type
      }
      if(this.qr.type == 'entrance') {
        params.house_id = this.qr.house_id
        params.porch = this.qr.porch_number
      }
      if(this.qr.type == 'entry') {
        params.entry_id = this.qr.entry_id
        params.entry = this.qr.entry
      }
      try {
        const res = await this.$api.qr.qr_in(params);
        this.response = res.data
        console.log('result fetch api', JSON.stringify(res))
        this.scanBleStatus = true
        this.refreshDeviceList()
      } catch (error) {
        console.log('error fetch api', JSON.stringify(error))
        this.errorText = error.message
        this.dialog = true
        this.loading = false
      }
      
    },
    refreshDeviceList() {
      console.log("Bluetooth is enabled");
      this.loading = true
      if(this.scanBleStatus) {
        // eslint-disable-next-line
        ble.scan([], 2, this.successScan, this.onError);
      }
    },
    successScan(device) {

      if(device.name == this.response.wifi_name) {
        // eslint-disable-next-line
        ble.connect(device.id, this.onConnect, this.onError);
        console.log('connecting to -', JSON.stringify(device))
        this.device_id = device.id
      }
      if(!this.connected && this.loading) {
        setTimeout(() => {
          console.log("NOT FOUND") 
          this.errorText = 'Устройство не найдено'
          this.dialog = true
          this.loading = false
        }, 3000);
      }
    },
    onConnect() {
      console.log('CONNECTED')
      this.connected = true
      this.writeBle()
    },
    writeBle() {
      if(this.connected && this.device_id) {
        console.log("WRIIIITE")// eslint-disable-next-line
        ble.write(this.device_id, this.BTModule.SERVICE_UUID, this.BTModule.CHARACTERISTIC_UUID, this.stringToBytes(this.response.wifi_password), this.successOpen, this.failureOpen);
      }
    },
    successOpen() {
      console.log('SUCCESS')
      this.setLogs()
      this.openSuccess = true
      // eslint-disable-next-line
      ble.disconnect(this.device_id, function() {
        console.log("DISCONNECT SUCCESS");
      }, function() {
        console.log("DISCONNECT FAIL");
      },
      )
      this.$router.push('/')
      this.$root.snackbar.show({ color: "success", message: 'Успешно' });
    },
    failureOpen() {
      console.log('FAIL')
      this.$root.snackbar.show({ color: "error", message: 'Произошла ошибка, повторите попытку!' });
      this.connected = false
    },
    bleIsEnabled() {
      console.log("Bluetooth is enabled");
      this.refreshDeviceList()
    },
    // bleDisable() {
    //   console.log("The user did *not* enable Bluetooth");
    //   this.$router.go(-1)
    // },
    bleOn() {
      // eslint-disable-next-line
      ble.enable(this.refreshDeviceList, function() {
        console.log("The user did *not* enable Bluetooth");
        window.location.assign(`/#/`);
      })
    },
    bleIsNotEnabled() {
      console.log("Bluetooth is *not* enabled");
      this.bleOn()
    },
    onDeviceReady() {
      // eslint-disable-next-line
      ble.isLocationEnabled(
        function() {
          console.log("location services are enabled");
        },
        function() {
          console.log("location services are *not* enabled");
        }
      );
      // eslint-disable-next-line
      ble.isEnabled(this.bleIsEnabled, this.bleIsNotEnabled );
      // eslint-disable-next-line
      console.log('device check', JSON.stringify(device))
      // eslint-disable-next-line
      this.cordova = device
      if (this.cordova.platform === 'Android') {
        document.addEventListener('backbutton', this.onBackButton, false)
      }
    },
    async setLogs() {
      let params = {
        datetime: new Date().toISOString().replace(/T/, ' ').replace(/\..+/, ''),
        type: this.qr.type
      }
      if(this.qr.type == 'entrance') {
        params.house_id = this.qr.house_id
        params.porch = this.qr.porch_number
      }
      if(this.qr.type == 'entry') {
        params.entry_id = this.qr.entry_id
        params.entry = this.qr.entry
      }
      try {
        const res = await this.$api.qr.qr_logs(params);
        console.log('logs fetch api', JSON.stringify(res))
      } catch (error) {
        console.log('error logs api', JSON.stringify(error))
      }
    },
    stringToBytes(string) {
      var array = new Uint8Array(string.length);
      for (var i = 0, l = string.length; i < l; i++) {
        array[i] = string.charCodeAt(i);
      }
      console.log(array.buffer)
      return array.buffer;
    },
    onBackButton () {
      this.$router.go(-1)
    },
    onError(reason) {
      if(reason?.errorMessage == 'Peripheral Disconnected' && this.availableFirstError && !this.openSuccess) {
        console.log('SECOND CHANCE ERROR')
        this.refreshDeviceList()
        this.availableFirstError = false
      } else {
        this.errorText = 'Произошла непредвиденная ошибка'
        this.dialog = true
      }
      console.log("ERROR: " + JSON.stringify(reason))
      // alert("ERROR: " + reason); // real apps should use notification.alert
    },
  },
  created () {
    console.log('cordova', Vue.cordova)// eslint-disable-next-line
    console.log('ble check', JSON.stringify(ble))

  },
  mounted() {
    document.addEventListener('deviceready', this.onDeviceReady, false);
  },
  components: {
    BackLogo,
    QrcodeStream
  }
}
</script>

<style scoped>

.scanner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
}
.scanner__section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scanner__title {
  color: #2EB85C;
}

.scanner__button {
  position: absolute;
  bottom: 16px;
  width: 50%;
}

.scanner__target {
  width: 250px;
  height: 250px;
  position: relative;
  padding: 20px;
  border: 1px solid #000;
}
.scanner__target:after {
    display: block;
    content: "";
    width: 35px;
    height: 35px;
    position: absolute;
    top: -20px;
    right: -20px;
    border-top: 4px solid #2EB85C;
    border-right: 4px solid #2EB85C;
    z-index: 2;
}
.scanner__target span.border-1:before {
    display: block;
    content: "";
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: -20px;
    left: -20px;
    border-bottom: 4px solid #2EB85C;
    border-left: 4px solid #2EB85C;
    z-index: 2;
}
.scanner__target span.border-2:before {
    display: block;
    content: "";
    width: 35px;
    height: 35px;
    position: absolute;
    top: -20px;
    left: -20px;
    border-top: 4px solid #2EB85C;
    border-left: 4px solid #2EB85C;
    z-index: 2;
}
.scanner__target span.border-3:before {
    display: block;
    content: "";
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: -20px;
    right: -20px;
    border-bottom: 4px solid #2EB85C;
    border-right: 4px solid #2EB85C;
    z-index: 2;
}

.scanner__mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
.mask-top {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}

.mask-middle {
  width: 100%;
  height: 250px;
  display: flex;
  position: relative;
}
.mask-left {
  width: calc((100% - 250px) / 2);
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}

.mask-right {
  width: calc((100% - 250px) / 2);
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}

.mask-bottom {
  width: 100%;
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
}

.flash-btn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
