<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon to="/")
      BackLogo
    v-app-bar-title.text-center(color="white") Голосование
  v-tabs(fixed-tabs v-model="tabs")
    v-tab(href="#tab-1" class="text-none") Текущие
    v-tab(href="#tab-2" class="text-none") Архив
  v-tabs-items(v-model="tabs")
    v-tab-item(value="tab-1")
      ListVotes(:data="current")
    v-tab-item(value="tab-2")
      ListVotes(:data="archive")
</template>

<script>
import BackLogo from "@/assets/img/back-button.svg"
import ListVotes from '../components/votes/listVotes.vue'
export default {
  data() {
    return {
      tabs: null,
      current: [],
      archive: []
    }
  },
  methods: {
    async getCurrentVotes() {
      try {
        const res = await this.$api.votes.load_votes();
        this.current = res
      } catch (error) {
        console.log('error', error)
      }
    },
    async getArchiveVotes() {
      try {
        const res = await this.$api.votes.load_archive_votes();
        this.archive = res
      } catch (error) {
        console.log('error', error)
      }
    },
  },
  created() {
    this.getCurrentVotes()
    this.getArchiveVotes()
  },
  components: {
    BackLogo,
    ListVotes
  }
}
</script>