<template lang="pug">
div
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon to="/houses" plain)
      BackLogo
    v-app-bar-title.text-center(color="white") Пользователи
  Spinner(v-if="!items.length")
  v-container(v-else)
    p В вашей квартире зарегистрированы следующие Пользователи
    v-list(v-for="(item, i) in items" :key="i")
      v-list-item
        v-list-item-content
          v-list-item-subtitle(v-if="item.approwed") {{ item.affilation }} (Подтверждён)
          v-list-item-subtitle(v-else) {{ item.affilation }} (Не подтверждён)
          v-list-item-title
            p.text-bold {{ item.user }}
            p.ma-0 Зарегистрирован {{ item.created_at | timestamp(true, { hideTime: true }) }}
      v-divider
</template>

<script>
import BackLogo from "@/assets/img/back-button.svg"
export default {
  data() {
    return {
      items: []
    }
  },
  async beforeMount() {
		this.items = await this.$api.houses.load_house_users(this.$route.params.id);
	},
  components: {
    BackLogo
  }
}
</script>