import { render, staticRenderFns } from "./houseAdd.vue?vue&type=template&id=451f06f4&scoped=true&lang=pug&"
import script from "./houseAdd.vue?vue&type=script&lang=js&"
export * from "./houseAdd.vue?vue&type=script&lang=js&"
import style0 from "./houseAdd.vue?vue&type=style&index=0&id=451f06f4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "451f06f4",
  null
  
)

export default component.exports