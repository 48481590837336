<template lang="pug">
div 
  v-app-bar(elevation="0" color="primary" dark app)
    v-btn(icon to="/menu/settings" plain)
      BackLogo
    v-app-bar-title.text-center Удаление аккаунта
  v-container
    template(v-if="!confirm")
      span(class="subtitle-1 font-weight-regular mt-2") Почему вы хотите удалить аккаунт?
      v-radio-group(v-model="radioGroup")
        v-radio(
          v-for="item in items"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        )
      v-btn.py-5(block color="primary" :disabled="!radioGroup" @click="confirm = true") Продолжить
    template(v-else)
      span(class="subtitle-1 font-weight-regular mt-2") Подтверждение удаление аккаунта
      p При удалении аккаунты все ваши данные и личная информация будут удалены без резервного копирования. Вы уверены, что хотите удалить свой аккаунт?
      v-btn(block color="red" class="white--text" :loading="loading" @click="deleteAccount") Удалить аккаунт
</template>

<script>
import BackLogo from "@/assets/img/back-button.svg"
export default {
  data() {
    return {
      radioGroup: null,
      confirm: false,
      loading: false,
      items: [
        { id: 1, name: 'Я больше не пользуюсь приложением' },
        { id: 2, name: 'Я перехожу в другое приложение' },
        { id: 3, name: 'Другое' },
      ]
    }
  },
  methods: {
    deleteAccount() {
      this.loading = true
      setTimeout(() => {
          this.loading = false
          this.$store.dispatch('logout')
          this.$router.push('/login')
        }, 3000);
    }
  },
  components: {
    BackLogo
  }
}
</script>